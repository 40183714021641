import React from 'react';
import * as THREE from 'three';
import * as TWEEN from 'tween';
import * as d3 from 'd3';
import debounce from 'lodash.debounce';
import { CSS2DRenderer } from 'three-css2drender';
import { Wrapper, VisualContainer, HTML2DContainer } from './Grid3D.styled';
import { withRouter } from 'react-router-dom';

// Import classes
import ProjectTile from './Classes/ProjectTile';

// Import functions
import { onMouseMove } from './Functions/onMouseMove';
import { onMouseDown } from './Functions/onMouseDown';
import { onMouseUp } from './Functions/onMouseUp';
import { onTouchStart } from './Functions/onTouchStart';
import { aniCameraTo } from './Functions/aniCameraTo';

// Create OrbitControls
import ThreeOrbitControls from './CustomOrbitControls/CustomOrbitControls';
const OrbitControls = ThreeOrbitControls(THREE);

class Grid3D extends React.Component {
  constructor(props) {
    super(props);

    // If true allow additional camera controls
    this.devMode = false;

    // Parent container
    this.wrapperRef = React.createRef();
    this.canvasRef = React.createRef();
    this.htmlRef = React.createRef();

    // Resize
    this.debouncedResize = debounce(() => this.resize(), 200);

    // Visual settings
    this.settings = {
      projectTile: {
        dimensions: 4,
        columns: 3,
        rows: 2,
      },
      subTile: {
        mapping: {
          min: 2,
          max: 20,
        },
      },
      camera: {
        // Zoom levels + positions of camera
        start: { zoom: 0.06, x: 0, y: 100, z: -100 },
        introduction: { zoom: 0.045, x: -130, y: 130, z: 0 },
        overview: { zoom: 0.025, x: 75, y: 55, z: -85 },
        sorted: { zoom: 0.025, x: 100, y: 100, z: -100 },
        focussed: { zoom: 0.05, x: 230, y: 160, z: -100 },
        selected: { zoom: 0.2, x: 10, y: 160, z: 0 }, // x has to be set in order to calculate the rotations
      },
    };

    // Create dynamic d3 scales for each tile type (metric)
    this.tileHeightScalesArray = [];
    this.tileHeightScalesValues = []; // Keep track of the values rather then looping over all of the sub-tiles (create a new array with the values so we can determine the min and max)

    // Check if the data is available; if so only allow once to be added
    this.dataIsInitialized = false;
    this.lastSortingMethod = null;

    // Determine speed of camera in overview
    this.lastViewIsIntroduction = false;

    // Keep track of the selected tile(s)
    this.interactionObjects = []; // <-- Only keep track of the objects that are allowed to have interaction; also use these as reference for zooming
    this.hoverObject = null;
    this.selectedObject = null;

    // Show all project title labels in overview.
    this.showAllProjectLabels = true;
    this.grid = props.grid;
  }

  componentDidMount() {
    // Add event listener
    window.addEventListener('resize', this.debouncedResize);

    // Add itself (the canvas)
    this.canvasRef.addEventListener('mousemove', (event) => onMouseMove(event, this), false);
    this.canvasRef.addEventListener('mousedown', (event) => onMouseDown(event, this), false);
    this.canvasRef.addEventListener('mouseup', (event) => onMouseUp(event, this), false);
    this.canvasRef.addEventListener('touchstart', (event) => onTouchStart(event, this), false);

    // Set size
    this.width = this.canvasRef.clientWidth;
    this.height = this.canvasRef.clientHeight;

    // Create the scene
    this.scene = new THREE.Scene();

    // 3D mouse picking
    this.raycaster = new THREE.Raycaster();
    this.mouse3D = new THREE.Vector2();

    // Setup the camera
    this.camera = new THREE.OrthographicCamera(
      (-1 * this.width) / this.height,
      (1 * this.width) / this.height,
      1,
      -1,
      0.1,
      2000
    );

    this.camera.position.x = this.settings.camera.start.x;
    this.camera.position.y = this.settings.camera.start.y;
    this.camera.position.z = this.settings.camera.start.z;
    this.camera.zoom = this.settings.camera.start.zoom; // Starting position fot the camera; will morph to: this.settings.camera.overview

    this.camera.lookAt(0, 0, 0);
    this.camera.updateProjectionMatrix();

    // only for x and z so the camera will stay within the grid
    this.cameraBoundingBox = {
      x: { min: null, max: null },
      z: { min: null, max: null },
    };

    // Variable to store previous values
    this.previousControlsTarget = null;
    this.previousCameraPosition = null;

    // Create renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setClearColor('rgb(0,0,0)', 0.0);
    this.renderer.setSize(this.width, this.height);

    // Create 2D renderer
    this.labelRenderer = new CSS2DRenderer();
    this.labelRenderer.setSize(this.width, this.height);

    // Ref for HTML objects
    this.htmlRef.appendChild(this.labelRenderer.domElement);

    // target our ref
    this.canvasRef.appendChild(this.renderer.domElement);

    // Add OrbitControls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    // Disable some functionality
    this.controls.enableKeys = this.devMode;
    this.controls.enableRotate = this.devMode;
    this.controls.screenSpacePanning = this.devMode;

    // Change the pan button to the left mouse button
    this.controls.mouseButtons = { PAN: 0, ZOOM: 1, ORBIT: 2 };

    // Set zooming
    this.controls.zoomSpeed = 0.33;
    this.controls.minZoom = 0.015;
    this.controls.maxZoom = 0.5;

    // Update the contols to apply the changes
    this.controls.update();

    // Creat two mouse vectors so we can check if the mouse button down is a drag or a click
    this.mouseDownVector = new THREE.Vector2();
    this.mouseUpVector = new THREE.Vector2();

    // Bind tweens and animations (rather then creating new tweens we overwrite the current)
    this.cameraPositionTWEEN = new TWEEN.Tween(this.camera.position);
    this.controlsPositionTWEEN = new TWEEN.Tween(this.controls.target);
    this.cameraZoomTWEEN = new TWEEN.Tween(this.camera);

    // Create object to contain the background color
    this.backgroundColor = new THREE.Color('rgb(0,0,0)');
    // Add alpha value (so we can tween the alpha aswell)
    this.backgroundColor.a = parseFloat(0);
    // Create the tween
    this.backgroundColorTWEEN = new TWEEN.Tween(this.backgroundColor);

    // Start rendering
    this.start();

    // Make sure scene camera's etc. are set
    this.updateSceneState();
  }

  // Remove listeners
  componentWillUnmount() {
    this.stop();

    window.removeEventListener('resize', this.debouncedResize);
    this.canvasRef.removeEventListener('mousemove', onMouseMove);
    this.canvasRef.removeEventListener('mouseup', onMouseUp);
    this.canvasRef.removeEventListener('click', onMouseDown);
    this.canvasRef.removeEventListener('touchstart', onTouchStart);

    this.canvasRef.removeChild(this.renderer.domElement);
    this.htmlRef.removeChild(this.labelRenderer.domElement);
  }

  componentDidUpdate() {
    // Update camera, sorting etc.
    this.updateSceneState();
  }

  updateSceneState() {
    const {
      isIntroduction,
      isOverview,
      isFocussed,
      isSelected,
      showTitleLabels,
      selectedEntrySlug,
      sortingMethod,
      sortedEntries,
      setCameraCenterAllowance,
      getCameraCenterAllowance,
      grid,
    } = this.props;
    // Only allow to be triggered when data is available
    if (sortedEntries.length > 0) {
      // Set visibility labels (should be visible in the overview)
      this.showAllProjectLabels = showTitleLabels;

      // Only allow three data initialize once
      if (!this.dataIsInitialized) {
        // Create the scales
        this.intializeScales(sortedEntries);

        // Create the grid
        this.createTileGrid(sortedEntries);
      }

      // Different camera positions
      if (isIntroduction) {
        // Make sure all projects are visible
        this.updateProjectTiles(true, null, sortedEntries, sortingMethod);

        // Is initialized so just update and reset
        if (this.dataIsInitialized) {
          // Deselect so the labels will hide
          this.resetSelections();
        }

        // Reset the background color
        this.resetBackground();

        // Animate camera
        aniCameraTo(
          this,
          {
            x: this.settings.camera.introduction.x,
            y: this.settings.camera.introduction.y,
            z: this.settings.camera.introduction.z,
            zoom: this.settings.camera.introduction.zoom,
          },
          // Update target
          {
            x: 0,
            y: 0,
            z: 0,
          },
          this.dataIsInitialized ? 1500 : 2500,
          () => {
            if (this.props.isCameraFocussed) this.props.setCameraState(false);
          }
        );
      } else if (isOverview) {
        // Make sure all projects are visible
        this.updateProjectTiles(false, null, sortedEntries, sortingMethod);

        // Reset the background color
        this.resetBackground();

        // Back to overview
        if (this.props.isCameraFocussed) this.props.setCameraState(false);

        // Is initialized so just update and reset
        if (this.dataIsInitialized) {
          // Deselect so the labels will hide
          this.resetSelections();
        }
        const baseZoomLevel = this.settings.camera.overview.zoom;
        const baseCells = 36;
        const newCells = grid.columns * grid.rows;
        const calcZoomLevel = baseZoomLevel + (newCells - baseCells) * -0.0002;

        // Check if camera is allowed to center; this is needed to so the camera wont center while sorting
        if (getCameraCenterAllowance()) {
          // Determine additional offset when in default sorting -> more 'dynamic' to rigid (properly sorted)
          if (sortingMethod === 'default') {
            // Animate camera
            aniCameraTo(
              this,
              {
                x: this.settings.camera.overview.x,
                y: this.settings.camera.overview.y,
                z: this.settings.camera.overview.z,
                zoom: calcZoomLevel,
              },
              // Update target
              {
                x: 0,
                y: 0,
                z: 0,
              },
              // if last view was introduction rotate slowly
              this.lastViewIsIntroduction ? 3500 : 1500,
              () => {}
            );
            // Set camera when in sorted overview
          } else {
            // Animate camera
            aniCameraTo(
              this,
              {
                x: this.settings.camera.sorted.x,
                y: this.settings.camera.sorted.y,
                z: this.settings.camera.sorted.z,
                zoom: this.settings.camera.sorted.zoom,
              },
              // Update target
              {
                x: 0,
                y: 0,
                z: 0,
              },
              // if last view was introduction rotate slowly
              this.lastViewIsIntroduction ? 3500 : 1500,
              () => {}
            );
          }
        } else {
          // Reset camera allowance back to true; false will only be set within the sorting options
          setCameraCenterAllowance(true);
        }

        // If project selected = faster animations
      } else if (selectedEntrySlug !== null) {
        // Is initialized so just update and reset
        if (this.dataIsInitialized) {
          // Deselect so the labels will hide
          this.resetSelections();
        }

        // Update selected
        this.selectedObject = this.interactionObjects[selectedEntrySlug].referenceObject;

        // Set background color
        this.setBackground(
          this.selectedObject.data.color.rgb.r,
          this.selectedObject.data.color.rgb.g,
          this.selectedObject.data.color.rgb.b,
          0.33
        );

        if (isFocussed) {
          // Set focussed
          this.selectedObject.focussed();

          // Animate camera
          aniCameraTo(
            this,
            {
              x: this.settings.camera.focussed.x + this.selectedObject.positions.x,
              y: this.settings.camera.focussed.y + this.selectedObject.positions.y + 8,
              z: this.settings.camera.focussed.z + this.selectedObject.positions.z,
              zoom: this.settings.camera.focussed.zoom,
            },
            // Update target
            {
              x: this.selectedObject.positions.x,
              y: this.selectedObject.positions.y + 8,
              z: this.selectedObject.positions.z,
            },
            this.dataIsInitialized ? 1350 : 500,
            () => {}
          );
        } else if (isSelected) {
          // Show label if in focussed
          this.selectedObject.selected();

          // Animate camera
          aniCameraTo(
            this,
            {
              x: this.settings.camera.selected.x + this.selectedObject.positions.x,
              y: this.settings.camera.selected.y + this.selectedObject.positions.y,
              z: this.settings.camera.selected.z + this.selectedObject.positions.z,
              zoom: this.settings.camera.selected.zoom,
            },
            // Update target
            {
              x: this.selectedObject.positions.x,
              y: this.selectedObject.positions.y,
              z: this.selectedObject.positions.z,
            },
            this.dataIsInitialized ? 1500 : 500,
            () => {
              // Set the parent components to allow to show the meta-data
              if (!this.props.isCameraFocussed) this.props.setCameraState(true);
            }
          );
        }

        // Hide other tiles and highlight selected
        this.updateProjectTiles(false, this.selectedObject, sortedEntries, sortingMethod);
      }

      // Set to true after data is being set; boolean also used to determine speed of the animations
      if (sortedEntries.length > 0) this.dataIsInitialized = true;

      // Update last view
      this.lastViewIsIntroduction = isIntroduction;
    }
  }

  updateProjectTiles(isIntroduction, highlightedTile, sortedEntries, sortingMethod) {
    for (const key of Object.keys(this.interactionObjects)) {
      const tile = this.interactionObjects[key].referenceObject;

      // If its at init; build each pilar up one by one
      if (isIntroduction) {
        tile.introAnimationPillars(2000);
      } else {
        // If overview
        if (highlightedTile === null) {
          // Determine which pillars should be shown
          if (sortingMethod !== 'default') {
            // Highlight specific
            tile.morphInSpecificSubTile(sortingMethod, 750);
          } else {
            // On default show all pillars
            tile.morphIn(1500);
          }
          // When one is selected but current is not the selected > morphout
        } else if (highlightedTile.data.slug !== tile.data.slug) {
          tile.morphOut(1500);
          // When one is selected > morpin
        } else if (highlightedTile.data.slug === tile.data.slug) {
          tile.morphIn(1500);
        }
      }
    }
    // Update the positions based on the sorting method
    if (sortingMethod !== this.lastSortingMethod) {
      this.updateTileGrid(sortedEntries, sortingMethod, 750, 1000);
    }

    // Update last sorting method
    this.lastSortingMethod = sortingMethod;
  }

  setProjectFocus(highlightedTile) {
    for (const key of Object.keys(this.interactionObjects)) {
      const tile = this.interactionObjects[key].referenceObject;
      if (tile.data.slug !== highlightedTile.data.slug) {
        tile.defocussed();
      }
    }
  }

  resetProjectFocus() {
    for (const key of Object.keys(this.interactionObjects)) {
      const tile = this.interactionObjects[key].referenceObject;
      if (this.lastSortingMethod === 'default') {
        tile.reset();
      } else {
        tile.resetSpecificSubTile(this.lastSortingMethod);
      }
    }
  }

  resetSelections() {
    // Reset selected
    if (this.selectedObject !== null) this.selectedObject.deselect();
    this.selectedObject = null;

    // Reset hover
    if (this.hoverObject !== null) this.hoverObject.referenceObject.parent.reset();
    this.hoverObject = null;
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.update);
    }
  };

  stop = () => cancelAnimationFrame(this.frameId);

  update = () => {
    this.frameId = window.requestAnimationFrame(this.update);

    // Update tween so the transitions will be updated
    TWEEN.update();

    // Check if content is within range (max distance from the center)
    // Note: controls are being updated within this function as well
    this.stayWithinRange();

    // Render the scene
    this.renderScene();
  };

  stayWithinRange() {
    // Check if within range
    if (this.cameraBoundingBox.maxDistance !== null) {
      const center = new THREE.Vector3(0, 0, 0);
      const currentDistance = center.distanceTo(this.controls.target);
      if (currentDistance > this.cameraBoundingBox.maxDistance) {
        this.controls.target.x = this.previousControlsTarget.x;
        this.controls.target.y = this.previousControlsTarget.y;
        this.controls.target.z = this.previousControlsTarget.z;
        this.camera.position.x = this.previousCameraPosition.x;
        this.camera.position.y = this.previousCameraPosition.y;
        this.camera.position.z = this.previousCameraPosition.z;
      } else {
        // Save the current position
        this.previousControlsTarget = this.controls.target.clone();
        this.previousCameraPosition = this.camera.position.clone();
      }
    }
    // Update the controls
    this.controls.update();
  }

  renderScene = () => {
    // Render
    this.renderer.render(this.scene, this.camera);
    this.labelRenderer.render(this.scene, this.camera);
  };

  resize = () => {
    // Get the dimension from the parent
    const newWidth = this.wrapperRef.current.getBoundingClientRect().width;
    const newHeight = this.wrapperRef.current.getBoundingClientRect().height;

    // Update values
    this.width = newWidth;
    this.height = newHeight;

    // Update the renderer
    this.renderer.setSize(this.width, this.height);
    this.labelRenderer.setSize(this.width, this.height);

    // Update camera frustum
    this.camera.left = (-1 * this.width) / this.height;
    this.camera.right = (1 * this.width) / this.height;
    this.camera.top = 1;
    this.camera.bottom = -1;

    // Update the projection matrix in order for the camera to be updated
    this.camera.updateProjectionMatrix();
  };

  goToOverviewPage = () => {
    this.props.history.push(`/overview`);
  };

  goToFocussedPage = (slug) => {
    this.props.history.push(`/focussed/${slug}`);
  };

  goToProjectPage = (slug) => {
    this.props.history.push(`/experiment/${slug}`);
  };

  setBackground(r, g, b, a) {
    this.backgroundColorTWEEN
      .stop()
      .to({ r: r / 255, g: g / 255, b: b / 255, a: a }, 500)
      .easing(TWEEN.Easing.Cubic.InOut)
      // Update the actual background
      .onUpdate(() => {
        this.renderer.setClearColor(this.backgroundColor, this.backgroundColor.a);
      })
      .start();
  }

  resetBackground() {
    this.backgroundColorTWEEN
      .stop()
      .to({ r: 0, g: 0, b: 0, a: 0 }, 500)
      .easing(TWEEN.Easing.Cubic.InOut)
      // Update the actual background
      .onUpdate(() => {
        this.renderer.setClearColor(this.backgroundColor, this.backgroundColor.a);
      })
      .start();
  }

  intializeScales(entries) {
    // Create the scales
    entries.forEach((entry) => {
      if (!entry.gridspacer) {
        entry.metrics.forEach((metric) => {
          // Create a new scale
          this.createTileScale(metric.key, metric.value);
        });
      }
    });
  }

  updateTileGrid = (entries, sortingMethod, duration, delay) => {
    const tileDensity = Math.ceil(Math.sqrt(entries.length));

    const dimensions = this.settings.projectTile.dimensions;
    const tileWidth = dimensions * this.settings.projectTile.columns;
    const tileDepth = dimensions * this.settings.projectTile.rows;

    // Add some spacing between the tiles
    const totalWidth =
      tileWidth * (tileDensity - 1) +
      (tileDensity - 1) * dimensions * this.settings.projectTile.columns;
    const totalDepth =
      tileDepth * (tileDensity - 1) +
      (tileDensity - 1) * dimensions * this.settings.projectTile.rows;

    let xIndex = 0;
    let zIndex = 0;

    // Determine the position
    const positionX = d3
      .scaleLinear()
      .domain([tileDensity - 1, 0])
      .range([totalWidth / 2, -(totalWidth / 2)])
      .clamp(true);

    const positionZ = d3
      .scaleLinear()
      .domain([0, tileDensity - 1])
      .range([totalDepth / 2, -(totalDepth / 2)])
      .clamp(true);

    for (let index = 0; index < entries.length; index++) {
      // Only sort the actual projects
      if (!entries[index].gridspacer) {
        const tile = this.interactionObjects[entries[index].slug].referenceObject;

        // Update the position
        tile.update(positionX(xIndex), 0, positionZ(zIndex), duration, delay);
      }

      // Update index
      if (xIndex >= tileDensity - 1) {
        xIndex = 0;
        zIndex++;
      } else {
        xIndex++;
      }
    }
  };

  createTileGrid = (entries) => {
    const tileDensity = Math.ceil(Math.sqrt(entries.length));

    const dimensions = this.settings.projectTile.dimensions;
    const tileWidth = dimensions * this.settings.projectTile.columns;
    const tileDepth = dimensions * this.settings.projectTile.rows;

    // Add some spacing between the tiles
    const totalWidth =
      tileWidth * (tileDensity - 1) +
      (tileDensity - 1) * dimensions * this.settings.projectTile.columns;
    const totalDepth =
      tileDepth * (tileDensity - 1) +
      (tileDensity - 1) * dimensions * this.settings.projectTile.rows;

    let xIndex = 0;
    let zIndex = 0;

    // Determine the position
    const positionX = d3
      .scaleLinear()
      .domain([tileDensity - 1, 0])
      .range([totalWidth / 2, -(totalWidth / 2)])
      .clamp(true);

    const positionZ = d3
      .scaleLinear()
      .domain([0, tileDensity - 1])
      .range([totalDepth / 2, -(totalDepth / 2)])
      .clamp(true);

    for (let index = 0; index < tileDensity * tileDensity; index++) {
      // Check if contains data; only create tiles with actual data
      if (index < entries.length && !entries[index].gridspacer) {
        // Create new tile
        const newTile = new ProjectTile(
          this,
          this.scene,
          entries[index],
          this.settings.projectTile.columns,
          this.settings.projectTile.rows
        );

        // initialize
        newTile.initialize(positionX(xIndex), 0, positionZ(zIndex), tileWidth, 0, tileDepth);
        // Push for interaction
        const slug = entries[index].slug;
        // Use the slug as key
        this.interactionObjects[slug] = newTile.container;
      }

      // Update index
      if (xIndex >= tileDensity - 1) {
        xIndex = 0;
        zIndex++;
      } else {
        xIndex++;
      }
    }

    // Calculate max distance based on grid positions
    const a = new THREE.Vector3(positionX(0), 0, positionZ(0));

    // Store the distance from the center
    this.cameraBoundingBox.maxDistance = a.distanceTo(new THREE.Vector3(0, 0, 0)) * 1.5;

    // Create grid
    const gridScale = 7; // Must be an odd number
    this.createPlaneGrid(
      0,
      0,
      0,
      (tileDensity * this.settings.projectTile.columns +
        (tileDensity - 1) * this.settings.projectTile.columns) *
        gridScale,
      (tileDensity * this.settings.projectTile.rows +
        (tileDensity - 1) * this.settings.projectTile.rows) *
        gridScale,
      (dimensions * this.settings.projectTile.columns + totalWidth) * gridScale,
      (dimensions * this.settings.projectTile.rows + totalDepth) * gridScale
    );
  };

  createPlaneGrid(xOffset, yOffset, zOffset, xDensity, zDensity, totalWidth, totalDepth) {
    // Create a new group for the grid
    const container = new THREE.Group();

    // Scales
    const positionX = d3
      .scaleLinear()
      .domain([xDensity, 0])
      .range([-totalWidth / 2, totalWidth / 2])
      .clamp(true);

    const positionZ = d3
      .scaleLinear()
      .domain([0, zDensity])
      .range([-totalDepth / 2, totalDepth / 2])
      .clamp(true);

    const material = new THREE.LineBasicMaterial({
      color: 'rgb(220,220,220)',
      opacity: 0.19,
      transparent: true,
    });

    // Create x lines
    for (let x = 0; x <= xDensity; x++) {
      let geometry = new THREE.Geometry();
      geometry.vertices.push(
        new THREE.Vector3(xOffset + positionX(x), yOffset, zOffset + positionZ(0)),
        new THREE.Vector3(xOffset + positionX(x), yOffset, zOffset + positionZ(zDensity))
      );
      container.add(new THREE.Line(geometry, material));
    }

    // Create z lines
    for (let z = 0; z <= zDensity; z++) {
      let geometry = new THREE.Geometry();
      geometry.vertices.push(
        new THREE.Vector3(xOffset + positionX(0), yOffset, zOffset + positionZ(z)),
        new THREE.Vector3(xOffset + positionX(xDensity), yOffset, zOffset + positionZ(z))
      );
      container.add(new THREE.Line(geometry, material));
    }

    // Add the container to the world
    this.scene.add(container);
  }

  createTileScale(key, value) {
    // Create a array with scales so we can dynamically increase the amount
    if (!this.tileHeightScalesValues[key]) {
      // Create new 2D array
      this.tileHeightScalesValues.push([]);
      // And push value
      this.tileHeightScalesValues[key] = [value];
      // Create new scale
      this.tileHeightScalesArray[key] = d3.scaleLinear();
    } else {
      // Push value
      this.tileHeightScalesValues[key].push(value);
    }
  }

  getTileScale(key, value) {
    const scale = this.tileHeightScalesArray[key];
    const values = this.tileHeightScalesValues[key];
    // Update scales
    scale
      .domain([
        0,
        d3.max(values, (value) => {
          return value;
        }),
      ])
      .range([this.settings.subTile.mapping.min, this.settings.subTile.mapping.max])
      .clamp(true);
    // Return the scale
    return scale(value);
  }

  updateCursor = () => {
    // Add hover class when hovering
    d3.select(this.canvasRef).classed('hovered', this.hoverObject !== null ? true : false);
  };

  render() {
    return (
      <Wrapper ref={this.wrapperRef}>
        <VisualContainer
          ref={(canvasRef) => {
            this.canvasRef = canvasRef;
          }}
        />
        <HTML2DContainer
          ref={(htmlRef) => {
            this.htmlRef = htmlRef;
          }}
        />
      </Wrapper>
    );
  }
}

export default withRouter(Grid3D);
