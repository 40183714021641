import React from 'react';
import { Wrapper, CenterContainer } from './LoadingContainer.styled';

const LoadingContainer = () => {
	return (
		<Wrapper>
			<CenterContainer>Loading...</CenterContainer>
		</Wrapper>
	);
};

export default LoadingContainer;
