import React from 'react';
import { withRouter } from 'react-router-dom';

import {
	ProjectInfo,
	Title,
	ProjectLink,
	PrevNav,
	NextNav,
	NavigateWithFocuss,
	StyledNextSVG,
	StyledPreviousSVG,
	StyledMoreSVG
} from './HeadsUpDisplay.styled';

const HeadsUpDisplay = props => {
	const { entry, previousEntry, nextEntry } = props;

	const setFocuss = entry => {
		// Push
		props.history.push(`/focussed/${entry.slug}`);
	};

	return (
		<>
			<ProjectInfo>
				{typeof entry !== 'undefined' && (
					<Title to={`/experiment/${entry.slug}`}>{entry.short}</Title>
				)}
				{typeof entry !== 'undefined' && (
					<ProjectLink to={`/experiment/${entry.slug}`}>
						<span>View experiment details</span>
						<StyledMoreSVG />
					</ProjectLink>
				)}
			</ProjectInfo>
			{typeof entry !== 'undefined' && (
				<PrevNav>
					{/* Please note the previous fetches the next entry this due visual logic */}
					<NavigateWithFocuss onClick={() => setFocuss(nextEntry)}>
						<StyledPreviousSVG />
					</NavigateWithFocuss>
				</PrevNav>
			)}
			{typeof entry !== 'undefined' && (
				<NextNav>
					{/* Please note the previous fetches the previous entry this due visual logic */}
					<NavigateWithFocuss onClick={() => setFocuss(previousEntry)}>
						<StyledNextSVG />
					</NavigateWithFocuss>
				</NextNav>
			)}
		</>
	);
};

export default withRouter(HeadsUpDisplay);
