export const onMouseUp = (event, parent) => {
	// Check if there is mouse interaction. Boolean to make sure events are only triggert once
	let mouseEventTriggered = false;

	// Update the vector
	parent.mouseUpVector.x = (event.clientX / parent.width) * 2 - 1;
	parent.mouseUpVector.y = -(event.clientY / parent.height) * 2 + 1;

	// Get the distance
	const dragDistance = parent.mouseDownVector.distanceTo(parent.mouseUpVector);

	// Only allow left moue button
	if (event.button === 0 && dragDistance < 0.025) {
		// calculate mouse position in normalized device coordinates
		// (-1 to +1) for both components
		parent.mouse3D.x = (event.clientX / parent.width) * 2 - 1;
		parent.mouse3D.y = -(event.clientY / parent.height) * 2 + 1;

		// Check if intersects
		parent.raycaster.setFromCamera(parent.mouse3D, parent.camera);

		// Convert object array to array
		const interactionObjects = Object.keys(parent.interactionObjects).map(
			i => parent.interactionObjects[i]
		);

		// calculate objects intersecting the picking ray
		let intersects = parent.raycaster.intersectObjects(
			interactionObjects,
			true
		);

		if (intersects.length > 0) {
			for (var i = 0; i < intersects.length; i++) {
				const object = intersects[i].object;

				// Check if is allowed mouse interactions
				if (object.mouseInteractionAllowed && mouseEventTriggered === false) {
					const parentObject = object.referenceObject.parent;

					//	Zoom in if is already in focus
					if (parentObject.isFocussed) {
						parent.goToProjectPage(parentObject.getSlug());

						// Only allow being infocuss when in overview
					} else if (parent.selectedObject === null) {
						// Focuss
						parent.goToFocussedPage(parentObject.getSlug());
					}

					// Disable panning controls when 'hovering' on a project tile
					parent.controls.enabled = false;

					// Set to true so only this event will be triggered
					mouseEventTriggered = true;
				} else {
					// Re-enable controls
					parent.controls.enabled = true;
				}
			}
		}
	}

	// Reset
	mouseEventTriggered = false;
};
