import React from 'react';
import { useSpring, animated } from 'react-spring';
import {
	Wrapper,
	InnerLink,
	Media,
	ScreenShot,
	Center,
	Title
} from './ExperimentListEntry.styled';

// Create Ani wrapper
const AniWrapper = animated(Wrapper);

const ExperimentListEntry = props => {
	const { entry } = props;

	// Some basic animations
	const fadeIn = useSpring({
		config: { duration: 350 },
		to: { opacity: 1 },
		from: { opacity: 0 },
		// Add some delay for fade in effect
		delay: 250 * (1 + entry.index)
	});

	return (
		<AniWrapper style={fadeIn}>
			<InnerLink to={`/experiment/${entry.slug}`}>
				<Media>
					<ScreenShot
						src={entry.media.png}
						alt={entry.short}
					/>
				</Media>
			</InnerLink>
			<Center>
				<Title>{entry.short}</Title>
			</Center>
		</AniWrapper>
	);
};

export default ExperimentListEntry;
