import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

import { ReactComponent as MenuSVG } from '../../images/icons/menu.svg';
import { ReactComponent as OverviewSVG } from '../../images/icons/overview.svg';
import { ReactComponent as SortSVG } from '../../images/icons/sort.svg';
import { ReactComponent as AboutSVG } from '../../images/icons/about.svg';
import { ReactComponent as CloseSVG } from '../../images/icons/close.svg';
import { ReactComponent as CloseSmallSVG } from '../../images/icons/close-small.svg';

export const HeaderWrapper = styled.div`
	position: fixed;
	width: 100%;
	z-index: 11;
	pointer-events: none;

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		background: ${({ theme }) => theme.white};
		width: 100%;
	}
`;

export const LogoWrapper = styled.div`
	display: inline-block;
	vertical-align: middle;
	background: ${({ theme }) => theme.black};
	padding: ${({ theme }) => theme.padding / 2 + 'px'};
	width: 64px;
	height: 64px;
`;

export const Logo = styled.img`
	display: inline-block;
	width: inherit;
	height: inherit;
	transform: translateY(-3px);
`;

export const InnerLink = styled(NavLink)`
	border: none;
	pointer-events: all;
	text-decoration: none;
	vertical-align: middle;
	display: inline-block;
`;

export const Title = styled.div`
	font-size: ${({ theme }) => theme.typography.medium};
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
	color: ${({ theme }) => theme.black};
	padding: ${({ theme }) => theme.padding * 2 + 'px'};
	text-transform: uppercase;
	letter-spacing: 0.12em;
	line-height: 1;

	padding-top: ${({ theme }) => 4 + theme.padding * 2 + 'px'};

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		display: none;
	}
`;

export const Home = styled.div`
	display: inline-block;
	background: ${({ theme }) => theme.white};

	&:hover {
		cursor: pointer;
	}
`;

export const Menu = styled.div`
	position: absolute;
	pointer-events: all;
	user-select: none;
	right: 0px;
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
	display: inline-block;
	vertical-align: middle;
	margin: ${({ theme }) => theme.margin * 1.5 + 'px'};
	float: right;
	color: ${({ theme }) => theme.white};
	text-transform: uppercase;
	&:hover {
		cursor: pointer;
	}
`;

export const SortOptionsContainer = styled.div`
	display: block;
	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		margin-top: ${({ theme }) => theme.margin + 3 + 'px'};
	}
`;

export const SortOption = styled.div`
	border-top: 1px solid rgba(0, 0, 0, 0.5);
	display: block;
	vertical-align: middle;
	pointer-events: all;
	font-size: ${({ theme }) => theme.typography.sortmenuoption};
	color: ${({ theme }) => theme.black};
	background: ${({ theme }) => theme.white};
	padding: ${({ theme }) => theme.padding * 1 + 'px'};
	margin-left: ${({ theme }) => theme.margin * 1 + 'px'};
	letter-spacing: 0.12em;
	transition: color 500ms, background 500ms;
	display: flex;
	&:hover {
		color: ${({ theme }) => theme.white};
		background: ${({ theme }) => theme.black};
		cursor: pointer;
	}
`;

export const SortOptionLabel = styled.div`
	padding-top: ${({ theme }) => theme.padding * 0.5 + 'px'};
`;

export const CloseSortOption = styled.div`
	display: inline-block;
	text-align: right;
	vertical-align: middle;
	flex: 1;
`;

export const SimpleMenuEntry = styled.div`
	display: inline-block;
	vertical-align: middle;
	pointer-events: all;
	font-size: ${({ theme }) => theme.typography.medium};
	color: ${props =>
		props.isActive ? ({ theme }) => theme.black : ({ theme }) => theme.white};
	background: ${props =>
		props.isActive ? ({ theme }) => theme.white : ({ theme }) => theme.black};
	padding: ${({ theme }) => theme.padding * 1 + 'px'};
	margin-left: ${({ theme }) => theme.margin * 1 + 'px'};
	letter-spacing: 0.12em;
	transition: all 500ms;
	text-decoration: none;

	&:hover {
		cursor: pointer;
		color: ${({ theme }) => theme.black};
		background: ${({ theme }) => theme.white};
	}
	&.active {
	}

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		svg {
			margin-right: 0px;
		}
		span {
			display: none;
		}
		span.no-icon {
			display: inline-block;
		}
	}

	padding-top: ${({ theme }) => 1 + theme.padding + 'px'};
`;

export const MenuEntry = styled(NavLink)`
	display: inline-block;
	vertical-align: middle;
	pointer-events: all;
	font-size: ${({ theme }) => theme.typography.medium};
	color: ${({ theme }) => theme.white};
	background: ${({ theme }) => theme.black};
	padding: ${({ theme }) => theme.padding * 1 + 'px'};
	margin-left: ${({ theme }) => theme.margin * 1 + 'px'};
	letter-spacing: 0.12em;
	transition: all 500ms;
	text-decoration: none;

	&:hover {
		cursor: pointer;
		color: ${({ theme }) => theme.black};
		background: ${({ theme }) => theme.white};
	}
	&.active {
	}

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		svg {
			margin-right: 0px;
		}
		span {
			display: none;
		}
		span.no-icon {
			display: inline-block;
		}
	}

	padding-top: ${({ theme }) => 1 + theme.padding + 'px'};
`;

export const StyledMenuSVG = styled(MenuSVG)`
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
`;

export const StyledOverviewSVG = styled(OverviewSVG)`
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	transform: scale(1, 0.75) rotate(45deg);
`;

export const StyledSortSVG = styled(SortSVG)`
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	margin-left: ${({ theme }) => theme.margin * 0.25 + 'px'};
`;

export const StyledCloseSortingMenuSVG = styled(CloseSmallSVG)`
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
`;

export const StyledAboutSVG = styled(AboutSVG)`
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
`;

export const Close = styled(Link)`
	position: relative;
	display: block;
	text-align: right;
	pointer-events: all;
	&:hover {
		cursor: pointer;
	}
`;

export const StyledCloseSVG = styled(CloseSVG)`
	width: 48px;
	width: 48px;
	color: ${({ theme }) => theme.white};
	transition: all 500ms;
	pointer-events: all;

	&:hover {
		color: ${({ theme }) => theme.black};
	}

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		color: ${({ theme }) => theme.black};
		&:hover {
			color: ${({ theme }) => theme.black};
		}
	}
`;
