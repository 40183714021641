import * as TWEEN from 'tween';

export const aniCameraTo = (
	parent,
	targetPosition,
	controlsPosition,
	duration,
	callback
) => {
	let zoomDone = false;
	let positionDone = false;

	// Positioning
	parent.cameraPositionTWEEN
		.stop()
		.to(
			{ x: targetPosition.x, y: targetPosition.y, z: targetPosition.z },
			duration
		)
		.easing(TWEEN.Easing.Cubic.InOut)
		.onComplete(() => {
			positionDone = true;
			if (zoomDone && positionDone) callback();
		})
		.start();

	// Zoom
	parent.cameraZoomTWEEN
		.stop()
		.to({ zoom: targetPosition.zoom }, duration)
		.easing(TWEEN.Easing.Cubic.InOut)
		.onUpdate(() => {
			// Need to update the projecten matrix for the zoom to have an effect
			parent.camera.updateProjectionMatrix();
		})
		.onComplete(() => {
			zoomDone = true;
			if (zoomDone && positionDone) callback();
		})
		.start();

	// Update the target of the controls
	parent.controlsPositionTWEEN
		.stop()
		.to(
			{ x: controlsPosition.x, y: controlsPosition.y, z: controlsPosition.z },
			duration
		)
		.easing(TWEEN.Easing.Cubic.InOut)
		.start();
};
