import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	vertical-align: top;
	display: inline-block;
	width: 45%;
	box-sizing: border-box;
	text-align: center;
	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		display: block;
		width: 100%;
	}
`;

export const MetricsWrapper = styled.div`
	position: absolute;
	width: 100%;
	top: 0px;
	box-sizing: border-box;
	pointer-events: none;
`;

export const MetricEntry = styled.div`
	display: inline-block;
	vertical-align: middle;
	box-sizing: border-box;
	width: 50%;

	&.left {
		text-align: left;
	}
	&.right {
		text-align: right;
	}

	.fade {
		transition: opacity 500ms;
		opacity: 0.33;
	}
	.default {
		transition: opacity 500ms;
		opacity: 1;
	}

	padding-bottom: ${({ theme }) => theme.padding * 5 + 'px'};
`;

export const MetricSquare = styled.div`
	display: inline-block;
	vertical-align: middle;
	box-sizing: border-box;
	padding-bottom: ${({ theme }) => theme.padding * 2 + 'px'};
	pointer-events: all;
	&:hover {
		cursor: pointer;
	}
`;

export const MetricValue = styled.div`
	font-size: ${({ theme }) => theme.typography.verylarge};
	padding-top: ${({ theme }) => theme.padding + 'px'};
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
`;

export const MetricLabel = styled.div`
	font-size: ${({ theme }) => theme.typography.small};
	text-transform: uppercase;
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
`;
