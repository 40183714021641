import React from 'react';

import Header from './../../components/Header/Header';
import SingleExperiment from './../../components/SingleExperiment/SingleExperiment';

const Experiment = props => {
	const {
		siteData,
		selectedEntryIndex,
		entry,
		previousEntry,
		nextEntry,
		isCameraFocussed,
		extremeValues
	} = props;
	return (
		<>
			{/* Header; depending on view determine to show menu or closing icon */}
			<Header title={siteData.title} showMenu={false} showClose={true} />
			{/* Show when camera is done zooming */}
			{isCameraFocussed && selectedEntryIndex !== null && (
				<SingleExperiment
					entry={entry}
					previousEntry={previousEntry}
					nextEntry={nextEntry}
					extremeValues={extremeValues}
				/>
			)}
		</>
	);
};

export default Experiment;
