import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// 3D components
import Grid3D from './components/Grid3D/Grid3D';

// If loading
import LoadingContainer from './components/LoadingContainer/LoadingContainer';

// Import pages
import Introduction from './pages/Introduction/Introduction';
import About from './pages/About/About';
import Overview from './pages/Overview/Overview';
import Focussed from './pages/Focussed/Focussed';
import Experiment from './pages/Experiment/Experiment';
import List from './pages/List/List';

// User history to set pageviews
const history = createBrowserHistory();
const pageView = (location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

// Update pageViews
history.listen((location) => pageView(location));

const AppRouter = (props) => {
  const {
    siteData,
    experimentsDataIsLoaded,
    sortedEntries,
    extremeValues,
    getEntryBySlug,
    getPreviousEntry,
    getNextEntry,
    sortingMethod,
    sortEntriesBy,
    setCameraState,
    setCameraCenterAllowance,
    getCameraCenterAllowance,
    isCameraFocussed,
    setSortingMenu,
    sortingStateMenu,
    grid,
  } = props;

  // Trigger at the start; use the listen function to update on change
  useEffect(() => {
    pageView(history.location);
  }, []);

  return (
    <Router history={history}>
      <Switch>
        {/* 3D Grid Overview */}
        <Route
          exact
          path="/overview"
          render={() => {
            return (
              <>
                <Grid3D
                  isIntroduction={false}
                  isOverview={true}
                  isSelected={false}
                  isFocussed={false}
                  showTitleLabels={false}
                  selectedEntrySlug={null}
                  sortingMethod={sortingMethod}
                  sortedEntries={sortedEntries}
                  // Check if the camera has a project in focus
                  setCameraState={setCameraState}
                  setCameraCenterAllowance={setCameraCenterAllowance}
                  getCameraCenterAllowance={getCameraCenterAllowance}
                  isCameraFocussed={isCameraFocussed}
                  grid={grid}
                />
                <Overview
                  siteData={siteData}
                  sortEntriesBy={sortEntriesBy}
                  setSortingMenu={setSortingMenu}
                  sortingStateMenu={sortingStateMenu}
                  setCameraCenterAllowance={setCameraCenterAllowance}
                />
              </>
            );
          }}
        />
        {/* Single Entry */}
        <Route
          exact
          path="/focussed/:experimentSlug"
          render={(innerProps) => {
            // Get the experimentSlug
            const { experimentSlug } = innerProps.match.params;
            // Check if data is loaded
            if (experimentsDataIsLoaded) {
              // Check if entry exists within the data else re-direct to introduction page
              if (typeof getEntryBySlug(experimentSlug) !== 'undefined') {
                return (
                  <>
                    <Grid3D
                      isIntroduction={false}
                      isOverview={false}
                      isSelected={false}
                      isFocussed={true}
                      showTitleLabels={false}
                      selectedEntrySlug={experimentSlug}
                      sortingMethod={sortingMethod}
                      sortedEntries={sortedEntries}
                      // Check if the camera has a project in focus
                      setCameraState={setCameraState}
                      setCameraCenterAllowance={setCameraCenterAllowance}
                      getCameraCenterAllowance={getCameraCenterAllowance}
                      isCameraFocussed={isCameraFocussed}
                      grid={grid}
                    />
                    <Focussed
                      siteData={siteData}
                      selectedEntrySlug={experimentSlug}
                      entry={getEntryBySlug(experimentSlug)}
                      previousEntry={getPreviousEntry(experimentSlug)}
                      nextEntry={getNextEntry(experimentSlug)}
                    />
                  </>
                );
              } else {
                // Re-direct to introduction page when the project (slug) does not exists within the data.
                return <Redirect to={'/'} />;
              }
            } else {
              // Loading
              return <LoadingContainer></LoadingContainer>;
            }
          }}
        />
        <Route
          exact
          path="/experiment/:experimentSlug"
          render={(innerProps) => {
            // Get the experimentIndex
            const { experimentSlug } = innerProps.match.params;
            // Check if data is loaded
            if (experimentsDataIsLoaded) {
              // Check if entry exists within the data else re-direct to introduction page
              if (typeof getEntryBySlug(experimentSlug) !== 'undefined') {
                return (
                  <>
                    <Grid3D
                      isIntroduction={false}
                      isOverview={false}
                      isSelected={true}
                      isFocussed={false}
                      showTitleLabels={false}
                      selectedEntrySlug={experimentSlug}
                      sortingMethod={sortingMethod}
                      sortedEntries={sortedEntries}
                      // Check if the camera has a project in focus
                      setCameraState={setCameraState}
                      setCameraCenterAllowance={setCameraCenterAllowance}
                      getCameraCenterAllowance={getCameraCenterAllowance}
                      isCameraFocussed={isCameraFocussed}
                      grid={grid}
                    />
                    <Experiment
                      siteData={siteData}
                      selectedEntrySlug={experimentSlug}
                      entry={getEntryBySlug(experimentSlug)}
                      previousEntry={getPreviousEntry(experimentSlug)}
                      nextEntry={getNextEntry(experimentSlug)}
                      extremeValues={extremeValues}
                      isCameraFocussed={isCameraFocussed}
                    />
                  </>
                );
              } else {
                // Re-direct to introduction page when the project (slug) does not exists within the data.
                return <Redirect to={'/'} />;
              }
            } else {
              // Loading
              return <LoadingContainer></LoadingContainer>;
            }
          }}
        />
        {/* List Overview */}
        <Route
          exact
          path="/list"
          render={() => {
            return (
              <>
                <Grid3D
                  isIntroduction={false}
                  isOverview={true}
                  isSelected={false}
                  isFocussed={false}
                  showTitleLabels={false}
                  selectedEntryIndex={null}
                  sortingMethod={sortingMethod}
                  sortedEntries={sortedEntries}
                  // Check if the camera has a project in focus
                  setCameraState={setCameraState}
                  setCameraCenterAllowance={setCameraCenterAllowance}
                  getCameraCenterAllowance={getCameraCenterAllowance}
                  isCameraFocussed={isCameraFocussed}
                  grid={grid}
                />
                <List siteData={siteData} sortedEntries={sortedEntries} />
              </>
            );
          }}
        />
        {/* About */}
        <Route
          exact
          path="/about"
          render={() => {
            return (
              <>
                <Grid3D
                  isIntroduction={false}
                  isOverview={true}
                  isSelected={false}
                  isFocussed={false}
                  showTitleLabels={false}
                  selectedEntryIndex={null}
                  sortingMethod={sortingMethod}
                  sortedEntries={sortedEntries}
                  // Check if the camera has a project in focus
                  setCameraState={setCameraState}
                  setCameraCenterAllowance={setCameraCenterAllowance}
                  getCameraCenterAllowance={getCameraCenterAllowance}
                  isCameraFocussed={isCameraFocussed}
                  grid={grid}
                />
                <About siteData={siteData} />
              </>
            );
          }}
        />
        {/* 3D Grid Introduction as default page  */}
        <Route
          path="*"
          render={() => {
            return (
              <>
                <Grid3D
                  isIntroduction={true}
                  isOverview={false}
                  isSelected={false}
                  isFocussed={false}
                  showTitleLabels={false}
                  selectedEntryIndex={null}
                  sortingMethod={sortingMethod}
                  sortedEntries={sortedEntries}
                  // Check if the camera has a project in focus
                  setCameraState={setCameraState}
                  setCameraCenterAllowance={setCameraCenterAllowance}
                  getCameraCenterAllowance={getCameraCenterAllowance}
                  isCameraFocussed={isCameraFocussed}
                  grid={grid}
                />
                <Introduction siteData={siteData} />
              </>
            );
          }}
        />
      </Switch>
    </Router>
  );
};

export default AppRouter;
