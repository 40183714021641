import React from 'react';

import Header from './../../components/Header/Header';

const Overview = props => {
	const {
		siteData,
		sortEntriesBy,
		setCameraCenterAllowance,
		setSortingMenu,
		sortingStateMenu
	} = props;
	return (
		<>
			{/* Header; depending on view determine to show menu or closing icon */}
			<Header
				title={siteData.title}
				showMenu={true}
				showClose={false}
				showSorting={true}
				sortEntriesBy={sortEntriesBy}
				setSortingMenu={setSortingMenu}
				sortingStateMenu={sortingStateMenu}
				setCameraCenterAllowance={setCameraCenterAllowance}
			/>
		</>
	);
};

export default Overview;
