import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSpring, useSprings, animated } from 'react-spring';
import { easeQuadOut } from 'd3-ease';
import formatNumber from '../../helpers/formatNumber';

import {
	Wrapper,
	Background,
	Container,
	TitleContainer,
	StyledTagLineSVG,
	MetricsWrapper,
	MetricEntry,
	MetricSquare,
	MetricValue,
	MetricLabel,
	SkipButton,
	StyledMoreSVG,
	InnerLink
} from './IntroductionContainer.styled';

const AniMetricEntry = animated(MetricEntry);
const AniSkipButton = animated(SkipButton);

const IntroductionContainer = props => {
	const { siteData } = props;

	// Keep track of the metrics that are done animating; when === length; go to overview page
	let doneCounter = 0;

	// Animations
	const [springMetrics, setSpringMetrics] = useSprings(
		siteData.overview.metrics.length,
		() => ({
			config: { duration: 0 },
			opacity: 0,
			transform: 'translate3d(0,100%,0)'
		})
	);

	// Show skip
	const springSkipButton = useSpring({
		delay: 1000,
		config: { duration: 750 },
		to: { opacity: 1, transform: 'translate3d(0,0,0)' },
		from: { opacity: 0, transform: 'translate3d(0,100%,0)' }
	});

	// Update sentences with new props
	setSpringMetrics(index => ({
		// Use same delays as the 3D objects
		delay: 2000 + (750 * siteData.overview.metrics.length - 750 * index),
		to: [
			{
				config: { duration: 750, easing: easeQuadOut },
				opacity: 1,
				transform: 'translate3d(0,0,0)'
			},
			{
				// Just wait to trigger to the overview page
				config: { duration: 25000 },
				opacity: 1,
				transform: 'translate3d(0,0,0)'
			}
		],
		onRest: () => {
			// When all animations are done
			if (doneCounter === siteData.overview.metrics.length - 1) {
				// Navigate to the overview page
				props.history.push(`/overview`);
			}
			doneCounter++;
		}
	}));

	return (
		<Wrapper>
			<Background to={`/overview`} />
			<Container>
				<TitleContainer>
					<StyledTagLineSVG />
				</TitleContainer>
				{/* Introduction metrics */}
				<MetricsWrapper>
					{springMetrics.map((styleProps, index) => (
						<AniMetricEntry
							style={styleProps}
							key={siteData.overview.metrics[index].key}>
							<MetricSquare>
								<MetricValue>
									{formatNumber(siteData.overview.metrics[index].value)}
								</MetricValue>
								<MetricLabel>
									{siteData.overview.metrics[index].label}
									{/* Only show metrics when not... */}
									{siteData.overview.metrics[index].key !== 'code_commits' &&
									siteData.overview.metrics[index].key !== 'total_views' &&
									siteData.overview.metrics[index].key !== 'projects'
										? ` in ${siteData.overview.metrics[index].prefix}`
										: ''}
								</MetricLabel>
							</MetricSquare>
						</AniMetricEntry>
					))}
				</MetricsWrapper>
				{/* Skip Introduction */}
				<InnerLink to={`/overview`}>
					<AniSkipButton style={springSkipButton}>
						<span>Skip Intro</span>
						<StyledMoreSVG />
					</AniSkipButton>
				</InnerLink>
			</Container>
		</Wrapper>
	);
};
export default withRouter(IntroductionContainer);
