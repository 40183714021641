import React from 'react';
import {
	Wrapper,
	Container,
	Title,
	Description
} from './DescriptionContainer.styled';

class DescriptionContainer extends React.Component {
	render() {
		const { description } = this.props;
		return (
			<Wrapper>
				{description.map((entry, index) => (
					<Container key={index}>
						<Title>{entry.title}</Title>
						<Description>{entry.description}</Description>
					</Container>
				))}
			</Wrapper>
		);
	}
}

export default DescriptionContainer;
