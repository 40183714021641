import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as NextSVG } from '../../images/icons/next.svg';
import { ReactComponent as PreviousSVG } from '../../images/icons/previous.svg';
import { ReactComponent as MoreSVG } from '../../images/icons/more.svg';

export const ProjectInfo = styled.div`
	position: fixed;
	left: 50%;
	margin-left: -33.33%;
	max-width: 66.66%;
	bottom: 10vh;
`;

export const Title = styled(Link)`
	position: relative;
	display: block;
	text-decoration: none;
	font-size: ${({ theme }) => theme.typography.extralarge};
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
	background: ${({ theme }) => theme.white};
	color: ${({ theme }) => theme.black};
	padding: ${({ theme }) => theme.padding * 1.5 + 'px'};
	padding-left: ${({ theme }) => theme.padding * 1.75 + 'px'};
	padding-rigth: ${({ theme }) => theme.padding * 1.75 + 'px'};
	line-height: 1;
	letter-spacing: 0.06em;
	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		font-size: 28px;
		padding: ${({ theme }) => theme.padding + 'px'};
		padding-left: ${({ theme }) => theme.padding * 1.25 + 'px'};
		padding-rigth: ${({ theme }) => theme.padding * 1.25 + 'px'};
	}
`;

export const ProjectLink = styled(Link)`
	pointer-events: all;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
	padding: ${({ theme }) => theme.padding * 2 + 'px'};
	margin-top: ${({ theme }) => theme.margin + 'px'};

	font-size: ${({ theme }) => theme.typography.medium};
	letter-spacing: 0.12em;
	text-decoration: none;
	transition: all 500ms;
	background: ${({ theme }) => theme.black};
	color: ${({ theme }) => theme.white};
	text-transform: uppercase;

	${'' /* Add some additional top padding to align the text vertically */}
	padding-top: ${({ theme }) => 1 + theme.padding * 2 + 'px'};

	&:hover {
		cursor: pointer;
		background: ${({ theme }) => theme.white};
		color: ${({ theme }) => theme.black};
	}

	span {
		vertical-align: bottom;
		margin-right: 10px;
		line-height: 1;
	}

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		font-size: ${({ theme }) => theme.typography.medium};
		padding: ${({ theme }) => theme.padding + 'px'};
	}
`;

export const NavigateWithFocuss = styled.div`
	display: inline-block;
	padding: ${({ theme }) => theme.padding + 'px'};
	color: ${({ theme }) => theme.white};
	background: ${({ theme }) => theme.black};
	transition: all 500ms;

	&:hover {
		cursor: pointer;
		background: ${({ theme }) => theme.white};
		color: ${({ theme }) => theme.black};
	}

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		padding: ${({ theme }) => 0 + 'px'};
	}
`;

export const PrevNav = styled.div`
	position: fixed;
	left: 0px;
	top: 50%;
	transform: translateY(-50%);
`;
export const NextNav = styled.div`
	position: fixed;
	right: 0px;
	top: 50%;
	transform: translateY(-50%);
`;

export const StyledNextSVG = styled(NextSVG)`
	display: block;
`;

export const StyledPreviousSVG = styled(PreviousSVG)`
	display: block;
`;

const moveArrowAni = keyframes`
	0%{
		transform:translateX(0%);
		opacity:1;
	 }
	 35%{
		transform:translateX(0%);
		opacity:1;
	 }
     90% {
		transform:translateX(100%);
		 opacity: 0;
     }
`;

export const StyledMoreSVG = styled(MoreSVG)`
	animation: ${moveArrowAni} 1250ms ease-out infinite;
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
`;
