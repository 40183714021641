import React, { Component } from 'react';
import * as d3 from 'd3';

import { Wrapper, SVGContainer } from './IsoMetricChart.styled';

class IsoMetricChart extends Component {
	constructor(props) {
		super(props);
		// SVG layer
		this.chartRef = React.createRef();
		this.settings = {
			pillar: {
				dimensions: 60,
				// Inversed
				min: -180,
				default: -175, // min
				max: 0
			}
		};
		this.offsets = [
			{
				x: 0,
				y: 0
			},
			{
				x: this.settings.pillar.dimensions,
				y: 0
			},
			{
				x: this.settings.pillar.dimensions * 2,
				y: 0
			},
			{
				x: 0,
				y: this.settings.pillar.dimensions
			},
			{
				x: this.settings.pillar.dimensions,
				y: this.settings.pillar.dimensions
			},
			{
				x: this.settings.pillar.dimensions * 2,
				y: this.settings.pillar.dimensions
			}
		];

		// Store the different scales (for each pillar)
		this.scales = [];
	}

	componentDidMount() {
		this.initialize();
		this.update();
	}

	componentDidUpdate() {
		this.update();
	}

	initialize = () => {
		const { width, height, extremeValues } = this.props;

		// Select target
		const svg = d3
			.select(this.chartRef.current)
			.attr('width', width)
			.attr('height', height);

		// Create a group
		this.pillars = svg
			.append('g')
			.attr('class', 'pillarsGroup')
			.attr('transform', () => {
				const x = this.settings.pillar.dimensions / 2;
				const y = 100 + this.settings.pillar.dimensions;
				return `translate(
                    ${width / 2 - x},
                    ${height / 2 - y})`;
			});

		// Convert positions to a shape
		this.shapeGenerator = d3
			.line()
			.x(d => {
				const iso = this.convertToIsoMetric({ x: d.x, y: d.z, z: d.y });
				return iso.x;
			})
			.y(d => {
				const iso = this.convertToIsoMetric({ x: d.x, y: d.z, z: d.y });
				return iso.y;
			})
			.curve(d3.curveLinearClosed);

		// Generate scales
		for (let key in extremeValues) {
			const entry = extremeValues[key];
			this.scales[key] = new d3.scaleLinear()
				.domain([0, entry.value])
				.range([this.settings.pillar.default, this.settings.pillar.max]);
		}
	};

	update = () => {
		const { data, hoverMetric } = this.props;
		// To ensure correct placement (to match with the HTML labels) we need to resort the entries
		const sortedData = [data[1], data[3], data[5], data[0], data[2], data[4]];
		const pillar = this.pillars.selectAll('.pillar').data(sortedData);

		// Non-existent
		pillar.exit().remove();

		// When new
		const newPillar = pillar
			.enter()
			.append('g')
			.attr('class', 'pillar')
			.on('mouseenter', d => {
				hoverMetric(d);
			})
			.on('mouseleave', () => {
				hoverMetric(null);
			});

		// Add content to the pillar group
		newPillar
			.append('path')
			.attr('class', 'bottom')
			.attr('d', (d, i) => {
				const positions = this.createRect(
					this.offsets[i].x,
					this.offsets[i].y,
					this.settings.pillar.min,
					this.settings.pillar.dimensions,
					this.settings.pillar.dimensions
				);
				// Store the cords so we can use these to generate the sides
				d.bottom = positions;
				return this.shapeGenerator(positions);
			});

		newPillar
			.append('path')
			.attr('class', 'top')
			.attr('d', (d, i) => {
				const positions = this.createRect(
					this.offsets[i].x,
					this.offsets[i].y,
					this.settings.pillar.min,
					this.settings.pillar.dimensions,
					this.settings.pillar.dimensions
				);
				// Store the cords so we can use these to generate the sides
				d.top = positions;
				return this.shapeGenerator(positions);
			});

		newPillar
			.append('path')
			.attr('class', 'left')
			.attr('d', d => {
				const positions = [d.top[2], d.top[1], d.bottom[1], d.bottom[2]];
				return this.shapeGenerator(positions);
			});

		newPillar
			.append('path')
			.attr('class', 'right')
			.attr('d', d => {
				const positions = [d.top[3], d.top[2], d.bottom[2], d.bottom[3]];
				return this.shapeGenerator(positions);
			});

		// Update content; and animate
		this.draw();
	};

	draw = () => {
		const { highlightedMetric, delay } = this.props;

		// Animations etc.
		const pillar = this.pillars
			.selectAll('.pillar')
			.transition()
			.delay(delay)
			.duration(500);

		pillar.select('.top').attr('d', (d, i) => {
			let z;
			// Determine the height of the bars
			if (highlightedMetric === null || d.key === highlightedMetric) {
				z = this.scales[d.key](d.value);
			} else {
				z = this.settings.pillar.min;
			}

			// We need to update the bottom and top positions
			let positions = this.createRect(
				this.offsets[i].x,
				this.offsets[i].y,
				this.settings.pillar.min,
				this.settings.pillar.dimensions,
				this.settings.pillar.dimensions
			);
			// Store the cords so we can use these to generate the sides
			d.bottom = positions;
			positions = this.createRect(
				this.offsets[i].x,
				this.offsets[i].y,
				z,
				this.settings.pillar.dimensions,
				this.settings.pillar.dimensions
			);
			// Store the cords so we can use these to generate the sides
			d.top = positions;
			return this.shapeGenerator(positions);
		});

		pillar.select('.left').attr('d', d => {
			const positions = [d.top[2], d.top[1], d.bottom[1], d.bottom[2]];
			return this.shapeGenerator(positions);
		});
		pillar.select('.right').attr('d', d => {
			const positions = [d.top[3], d.top[2], d.bottom[2], d.bottom[3]];
			return this.shapeGenerator(positions);
		});
	};

	convertToIsoMetric = input => {
		const x = (input.x - input.z) * Math.cos(Math.PI / 6);
		const y = -input.y + (input.x + input.z) * Math.sin(Math.PI / 6);
		return { x: x, y: y };
	};

	createRect = (x, y, z, width, height) => {
		return [
			{ x: x, y: y, z: z },
			{ x: x + width, y: y, z: z },
			{ x: x + width, y: y + height, z: z },
			{ x: x, y: y + height, z: z }
		];
	};

	render() {
		return (
			<Wrapper>
				<SVGContainer ref={this.chartRef} />
			</Wrapper>
		);
	}
}

export default IsoMetricChart;
