import styled from 'styled-components';

export const Wrapper = styled.div`
	display: block;
	text-align: center;
`;

export const SVGContainer = styled.svg`
	pointer-events: all;
	.pillar {
		.top,
		.bottom,
		.left,
		.right {
			stroke: rgb(35, 31, 32);
			fill: white;
			&:hover {
				cursor: pointer;
			}
		}
		text {
			text-anchor: end;
		}
	}
`;
