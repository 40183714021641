import React from 'react';

import IntroductionContainer from '../../components/IntroductionContainer/IntroductionContainer';

const Introduction = props => {
	const { siteData } = props;
	return (
		<>
			<IntroductionContainer siteData={siteData} />
		</>
	);
};

export default Introduction;
