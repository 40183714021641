import Tile from './SubTile';
import * as THREE from 'three';
import * as TWEEN from 'tween';
import * as d3 from 'd3';
import { CSS2DObject } from 'three-css2drender';

class ProjectTile {
	constructor(parent, scene, data, columns, rows) {
		this.parent = parent;

		// Save the project data
		this.targetScene = scene;
		this.data = data;

		// Keep track
		this.positions = { x: 0, y: 0, z: 0 };
		this.scales = { xScale: 0, yScale: 0, zScale: 0 };

		// Keep track of the amount of tiles that need to be created
		this.rows = rows; // x
		this.columns = columns; // z
		this.subTiles = [];

		// keep track of the label
		this.labelObject = null;

		// Just as a group; no scaling etc.
		this.container = new THREE.Group();

		// Create reference
		this.container.referenceObject = this;

		// Group; scale position the group
		this.group = new THREE.Group();

		// Animations
		this.positionTween = new TWEEN.Tween(this.group.position);

		// Contain the div; so we can target with classes
		this.label = null;

		// Interaction
		this.isSelected = false;
		this.isFocussed = false;
	}

	initialize(x, y, z, xScale, yScale, zScale) {
		// Update the positions and scales
		this.positions.x = x;
		this.positions.y = y;
		this.positions.z = z;

		this.scales.xScale = xScale;
		this.scales.yScale = yScale;
		this.scales.zScale = zScale;

		// Add a label
		this.createLabel(this.targetScene, this.positions);

		// Update the position
		this.group.position.x = this.positions.x;
		this.group.position.y = this.positions.y;
		this.group.position.z = this.positions.z;

		// Add to the container
		this.container.add(this.group);

		// Add to the parent scene
		this.targetScene.add(this.container);

		// Create subtiles
		this.createSubTiles(
			this.columns,
			this.rows,
			this.scales.xScale,
			this.scales.yScale,
			this.scales.zScale
		);

		// Animate to the correct height
		this.updatePosition(
			this.positions.x,
			this.positions.y,
			this.positions.z,
			1500,
			0
		);
	}

	update(x, y, z, duration, delay) {
		this.positions.x = x;
		this.positions.y = y;
		this.positions.z = z;

		// Animate to the correct height
		this.updatePosition(
			this.positions.x,
			this.positions.y,
			this.positions.z,
			duration,
			delay
		);

		// Update CSS
		this.labelObject.position.set(this.positions.x, 1, this.positions.z);
	}

	introAnimationPillars(startDelay) {
		let index = 0;
		for (const key of Object.keys(this.subTiles)) {
			const subTile = this.subTiles[key];

			// delay
			let delay = startDelay + index * 750;

			// Animation
			subTile.morphIn(1500, delay);
			subTile.updateOpacity(0.75, 1500, delay);

			index++;
		}
	}

	createLabel(targetScene, position) {
		// Create DIV
		this.label = document.createElement('div');

		// Set content
		this.label.innerHTML = `<span>${this.data.short}</span>`;
		this.label.className = 'label';

		this.labelObject = new CSS2DObject(this.label);
		this.labelObject.position.set(position.x, 1, position.z);

		// Add to the scene
		targetScene.add(this.labelObject);

		// Show labels on default when in overview mode
		if (this.parent.showAllProjectLabels) this.showLabel();
	}

	createSubTiles(columns, rows, totalTileWidth, parentHeight, totalTileDepth) {
		const tileAmount = columns * rows;

		// Determine the position
		const positionX = d3
			.scaleLinear()
			.domain([0, columns])
			.range([-totalTileWidth / 2, totalTileWidth / 2]);

		const positionZ = d3
			.scaleLinear()
			.domain([0, rows])
			.range([-totalTileDepth / 2, +totalTileDepth / 2]);

		const tileWidth = totalTileWidth / columns;
		const tileDepth = totalTileDepth / rows;

		let xIndex = 0;
		let zIndex = 0;

		// Get the highest level
		const globalScales = this.parent;

		for (let i = 0; i < tileAmount; i++) {
			// Data
			const data = this.data.metrics[i];

			// Get the height
			const height = globalScales.getTileScale(data.key, data.value);

			// Create new grid tile
			const newSubTile = new Tile(this, this.group, data);

			newSubTile.initialize(
				positionX(xIndex) + tileWidth / 2,
				parentHeight + height / 2,
				positionZ(zIndex) + tileDepth / 2,
				tileWidth,
				height,
				tileDepth,
				i
			);

			// Save the tile
			this.subTiles[data.key] = newSubTile;

			// Update indexes
			if (xIndex >= columns - 1) {
				xIndex = 0;
				zIndex++;
			} else {
				xIndex++;
			}
		}
	}

	updatePosition(x, y, z, duration, delay) {
		this.positionTween
			.stop()
			.to({ x: x, y: y, z: z }, duration)
			.delay(delay)
			.easing(TWEEN.Easing.Cubic.InOut)
			.start();
	}

	getSlug() {
		return this.data.slug;
	}

	getData() {
		return this.data;
	}

	getObject() {
		return this;
	}

	selected() {
		this.isSelected = true;

		// Hide labels
		for (const key of Object.keys(this.subTiles))
			this.subTiles[key].hideLabel(500);
	}

	focussed() {
		this.isFocussed = true;

		// Show labels
		for (const key of Object.keys(this.subTiles)) {
			this.subTiles[key].showLabel(1500);
		}
	}

	deselect() {
		this.isSelected = false;
		this.isFocussed = false;

		// update sub tiles
		for (const key of Object.keys(this.subTiles))
			this.subTiles[key].hideLabel(1500);
	}

	defocussed() {
		this.hideLabel();

		// update sub tiles
		for (const key of Object.keys(this.subTiles))
			this.subTiles[key].updateOpacity(0.165, 350, 0);
	}

	reset() {
		// Hide when not in overview
		if (!this.parent.showAllProjectLabels) this.hideLabel();
		else this.showLabel();

		// update sub tiles
		for (const key of Object.keys(this.subTiles)) this.subTiles[key].reset();
	}

	resetSpecificSubTile(subTileKey) {
		// Hide when not in overview
		if (!this.parent.showAllProjectLabels) this.hideLabel();
		else this.showLabel();

		for (const key of Object.keys(this.subTiles)) {
			// Reset fully when a project is selected
			if (subTileKey === key || this.parent.selectedObject !== null) {
				this.subTiles[key].reset();
			} else {
				this.subTiles[key].updateOpacity(0.165, 350, 0);
			}
		}
	}

	hover() {
		this.showLabel();
		// update sub tiles
		for (const key of Object.keys(this.subTiles)) this.subTiles[key].hover();
	}

	hoverSpecificSubTile(subTileKey) {
		this.showLabel();
		this.subTiles[subTileKey].hover();
	}

	showLabel() {
		// show title
		this.label.classList.add('show');
	}

	hideLabel() {
		// Hide title
		this.label.classList.remove('show');
	}

	updateLabel() {
		// Check if label should be visisble
		if (!this.parent.showAllProjectLabels) this.hideLabel();
		else this.showLabel();
	}

	morphIn(duration) {
		this.updateLabel();
		// update sub tiles
		for (const key of Object.keys(this.subTiles))
			this.subTiles[key].morphIn(duration, 0);
	}

	morphOut(duration) {
		this.updateLabel();
		// update sub tiles
		for (const key of Object.keys(this.subTiles))
			this.subTiles[key].morphOut(duration, 0);
	}

	morphInSpecificSubTile(subTileKey, duration) {
		for (const key of Object.keys(this.subTiles)) {
			// MorpIn specific
			if (key === subTileKey) {
				this.subTiles[key].morphIn(duration, 0);
			} else {
				this.subTiles[key].morphOut(duration, 0);
				// Make sure other pillars are slightly visible
				this.subTiles[key].updateOpacity(0.165, 350, 0);
			}
		}
	}
}

export default ProjectTile;
