import styled, { createGlobalStyle } from 'styled-components';
import globalStyling from './theme/global';

// Global body styling
export const BodyStyle = createGlobalStyle`
	${globalStyling}
`;

export const SiteWrapper = styled.div`
	height: 100%;
`;
