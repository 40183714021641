import React from 'react';

import Header from './../../components/Header/Header';
import HeadsUpDisplay from './../../components/HeadsUpDisplay/HeadsUpDisplay';

const Focussed = props => {
	const {
		siteData,
		entry,
		selectedEntrySlug,
		previousEntry,
		nextEntry
	} = props;
	return (
		<>
			{/* Header; depending on view determine to show menu or closing icon */}
			<Header title={siteData.title} showMenu={false} showClose={true} />
			{selectedEntrySlug !== null && (
				<HeadsUpDisplay
					entry={entry}
					previousEntry={previousEntry}
					nextEntry={nextEntry}
				/>
			)}
		</>
	);
};

export default Focussed;
