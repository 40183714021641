import React from 'react';
import ExperimentListEntry from '../ExperimentListEntry/ExperimentListEntry';

import { useSpring, animated } from 'react-spring';
import { Container, CloseBackground } from './ExperimentsList.styled';

const AniContainer = animated(Container);

const ExperimentsList = props => {
	const { sortedEntries } = props;
	const fadeIn = useSpring({
		config: { duration: 350 },
		to: { backgroundColor: 'rgba(0,0,0, 0.66)' },
		from: { backgroundColor: 'rgba(0,0,0,0)' }
	});

	return (
		<AniContainer style={fadeIn}>
			<CloseBackground to="/overview" />
			{sortedEntries.map(
				entry =>
					!entry.gridspacer && (
						<ExperimentListEntry entry={entry} key={entry.slug} />
					)
			)}
		</AniContainer>
	);
};

export default ExperimentsList;
