import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as LinkSVG } from '../../images/icons/link.svg';
import { ReactComponent as LogoSVG } from '../../images/cf_logo.svg';

export const CloseBackground = styled(Link)`
	position: fixed;
	display: block;
	margin: 0px;
	padding: 0px;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.66);
	z-index: 1;
	pointer-events: all;

	&:hover {
		cursor: default;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	height: 100vh;
	z-index: 9;
	pointer-events: none;
`;

export const ContentWrapper = styled.div`
	position: relative;
	display: inline-block;
	text-align: center;
	z-index: 9;
	padding-top: ${({ theme }) => theme.padding * 2 + 'px'};
`;

export const CenterContainer = styled.div`
	position: absolute;
	position: relative;
	display: inline-block;
	max-width: 600px;
	box-sizing: border-box;
	text-align: left;
	pointer-events: all;
	top: 140px;
	text-align: left;
	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		width: 100%;
		padding: 0px;
		top: 0px;
		margin-top: ${({ theme }) => theme.margin * 13 + 'px'};
	}
`;

export const Title = styled.div`
	font-size: ${({ theme }) => theme.typography.small};
	text-transform: uppercase;
	margin-bottom: ${({ theme }) => theme.margin / 2 + 'px'};
	padding-bottom: ${({ theme }) => theme.padding / 2 + 'px'};
	padding-top: ${({ theme }) => theme.padding * 4 + 'px'};
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
	letter-spacing: 0.12em;
`;

export const Description = styled.div`
	display: inline-block;
	padding-bottom: ${({ theme }) => theme.padding * 4 + 'px'};
	font-size: ${({ theme }) => theme.typography.medium};
	line-height: 150%;
	width: 100%;
`;

export const Container = styled.div`
	box-sizing: border-box;
	padding: ${({ theme }) => theme.padding * 6 + 'px'};
	background: ${({ theme }) => theme.white};

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		width: 100%;
		padding: ${({ theme }) => theme.padding * 2 + 'px'};
	}
`;

export const TagLine = styled.div`
	text-align: center;

	padding-bottom: ${({ theme }) => theme.padding * 2 + 'px'};
	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		padding: ${({ theme }) => theme.padding * 2 + 'px'};
	}
`;

export const StyledLogoSVG = styled(LogoSVG)`
	display: inline-block;
	width: 100%;
	${'' /* max-width: 420px; */}
`;

export const StyledExternalLinkSmallSVG = styled(LinkSVG)`
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
`;

export const ExternalLinkSmall = styled.a`
	display: inline-block;
	padding: ${({ theme }) => theme.padding * 2 + 'px'};
	font-size: ${({ theme }) => theme.typography.medium};
	background: ${({ theme }) => theme.black};
	color: ${({ theme }) => theme.white};
	border: 1px solid black;
	text-decoration: none;
	transition: all ${({ theme }) => theme.transitionSpeed};
	text-transform: uppercase;
	letter-spacing: 0.12em;
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
	transition: all 500ms;

	${'' /* Add some additional top padding to align the text vertically */}
	padding-top: ${({ theme }) => 1 + theme.padding * 2 + 'px'};

	&:hover {
		cursor: pointer;
		background: ${({ theme }) => theme.white};
		color: ${({ theme }) => theme.black};
	}

	span {
		vertical-align: bottom;
		margin-right: 10px;
		line-height: 1;
	}
`;
