import React from 'react';
import CFlogo from '../../images/cf_white.png';

import {
	HeaderWrapper,
	InnerLink,
	LogoWrapper,
	Logo,
	Title,
	Home,
	Menu,
	MenuEntry,
	StyledOverviewSVG,
	StyledAboutSVG,
	Close,
	StyledCloseSVG,
	SimpleMenuEntry,
	SortOptionsContainer,
	SortOption,
	SortOptionLabel,
	CloseSortOption,
	StyledSortSVG,
	StyledCloseSortingMenuSVG
} from './Header.styled';

class Header extends React.Component {
	render() {
		const {
			title,
			showMenu,
			showClose,
			showSorting,
			sortEntriesBy,
			setCameraCenterAllowance,
			setSortingMenu,
			sortingStateMenu
		} = this.props;

		// The metricIndex matches with the metrics for each project array
		const sortingOptions = [
			{
				label: 'Code Size In KB',
				key: 'code_size',
				metricIndex: 5
			},
			{
				label: 'File Size In MB',
				key: 'file_size',
				metricIndex: 4
			},
			{
				label: 'Total Views',
				key: 'total_views',
				metricIndex: 3
			},
			{
				label: 'Code Commits',
				key: 'code_commits',
				metricIndex: 2
			},
			{
				label: 'Time Spent In Hours',
				key: 'time_spent',
				metricIndex: 1
			},
			{
				label: 'Code Length In Lines',
				key: 'code_length',
				metricIndex: 0
			}
		];

		const onClickSort = (sortOption, sortKey, sortLabel) => {
			// Update menu
			setSortingMenu(false, true, sortLabel);
			// Update visual
			sortEntriesBy(sortOption, sortKey);
			// Allow the camera to be centered
			setCameraCenterAllowance(true);
		};

		const onClickReset = () => {
			// Update menu
			setSortingMenu(true, false, '');
			// Reset visual
			sortEntriesBy(null, 'default');
			// Allow the camera to be centered
			setCameraCenterAllowance(true);
		};

		const onClickToggleSortOptions = () => {
			// Allow the camera to be centered
			setCameraCenterAllowance(false);
			// Toggle menu
			if (!sortingStateMenu.showCurrentSelected) {
				setSortingMenu(
					!sortingStateMenu.showSortingOptions,
					sortingStateMenu.showCurrentSelected,
					sortingStateMenu.currentSelectedLabel
				);
			} else {
				// Reset when clicking on menu a second time
				onClickReset();
			}
		};

		return (
			<HeaderWrapper>
				<Home>
					<InnerLink to={`/`}>
						<LogoWrapper>
							<Logo src={CFlogo} />
						</LogoWrapper>
					</InnerLink>
					<InnerLink to={`/`}>
						<Title>{title}</Title>
					</InnerLink>
				</Home>
				{/* Menu options */}
				{showMenu && (
					<>
						<Menu>
							{showSorting && (
								<SimpleMenuEntry
									onClick={onClickToggleSortOptions}
									isActive={
										sortingStateMenu.showSortingOptions ||
										sortingStateMenu.showCurrentSelected
									}>
									Sort By <StyledSortSVG></StyledSortSVG>
								</SimpleMenuEntry>
							)}
							<MenuEntry to={`/overview`}>
								<StyledOverviewSVG />
							</MenuEntry>
							<MenuEntry to={`/about`}>
								<StyledAboutSVG />
							</MenuEntry>
							{sortingStateMenu.showSortingOptions && (
								<SortOptionsContainer>
									{sortingOptions.map(entry => (
										<SortOption
											key={entry.key}
											onClick={() => {
												onClickSort(entry.metricIndex, entry.key, entry.label);
											}}>
											<SortOptionLabel>› {entry.label}</SortOptionLabel>
										</SortOption>
									))}
								</SortOptionsContainer>
							)}
							{sortingStateMenu.showCurrentSelected && (
								<SortOptionsContainer>
									<SortOption
										key={'default'}
										onClick={() => {
											onClickReset();
										}}>
										<SortOptionLabel>
											{sortingStateMenu.currentSelectedLabel}
										</SortOptionLabel>
										<CloseSortOption>
											<StyledCloseSortingMenuSVG></StyledCloseSortingMenuSVG>
										</CloseSortOption>
									</SortOption>
								</SortOptionsContainer>
							)}
						</Menu>
					</>
				)}
				{/* Close X */}
				{showClose && (
					<Menu>
						<Close to={`/overview`}>
							<StyledCloseSVG />
						</Close>
					</Menu>
				)}
			</HeaderWrapper>
		);
	}
}

export default Header;
