import React from 'react';

import Header from './../../components/Header/Header';
import ExperimentsAbout from './../../components/ExperimentsAbout/ExperimentsAbout';

const About = ({ siteData }) => {
  return (
    <>
      {/* Header; depending on view determine to show menu or closing icon */}
      <Header title={siteData.title} showMenu={false} showClose={true} />
      <ExperimentsAbout aboutData={siteData.aboutText} />
    </>
  );
};

export default About;
