const variables = {
	typography: {
		verysmall: '10px',
		small: '12px',
		medium: '16px',
		large: '21px',
		verylarge: '36px',
		extralarge: '42px',
		introtitle: '32px',
		labels3d: '13px',
		sortmenuoption: '13px'
	},
	fontfamilies: {
		neutralReg: 'Neutral Std',
		neutralMed: 'Neutral Std Medium'
	},
	padding: 10,
	margin: 10,
	breakpoint: '760px',
	introBreakpoint: '1300px'
};

export default variables;
