import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
	position: relative;
	box-sizing: border-box;
	overflow: hidden;
	text-align: left;
	line-height: 0px;
	display: inline-block;
	text-align: left;
	padding: ${({ theme }) => theme.padding + 'px'};
`;

export const InnerLink = styled(Link)`
	border: none;
	pointer-events: all;
`;

export const Center = styled.div`
	position: absolute;
	line-height: auto;
	left: 50%;
	bottom: 50%;
	transform: translate(-50%, 50%);
	width: 100%;
	text-align: center;
	pointer-events: none;
	padding: ${({ theme }) => theme.padding + 'px'};
`;

export const Media = styled.div`
	position: relative;
	display: inline-block;
	background: ${({ theme }) => theme.black};
`;

export const ScreenShot = styled.img`
	top: 0px;
	left: 0px;
	width: 100%;
	opacity: 0.35;
	transition: opacity 500ms;

	&:hover {
		opacity: 1;
	}
`;

export const Title = styled.div`
	font-size: ${({ theme }) => theme.typography.extralarge};
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
	margin-bottom: ${({ theme }) => theme.margin / 2 + 'px'};
	padding: ${({ theme }) => theme.padding + 'px'};
	color: ${({ theme }) => theme.white};
	display: block;
	line-height: 120%;

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		font-size: ${({ theme }) => theme.typography.large};
	}
`;
