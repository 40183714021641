import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as MoreSVG } from '../../images/icons/more.svg';
import { ReactComponent as TagLineSVG } from '../../images/cf_intro_tagline.svg';

export const Background = styled(Link)`
	position: fixed;
	background: radial-gradient(rgba(20, 20, 20, 0) 55%, rgba(20, 20, 20, 0.1));
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	&:hover {
		cursor: default;
	}
`;

export const Wrapper = styled.div`
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	overflow: auto;
`;

export const Container = styled.div`
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translate(0, -50%);
	text-align: center;
	box-sizing: border-box;
	padding-left: 5%;
	padding-right: 5%;
	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		top: 0px;
		transform: translate(0px, 0px);
		margin-top: ${({ theme }) => theme.margin * 4 + 'px'};
		padding-left: ${({ theme }) => theme.padding + 'px'};
		padding-right: ${({ theme }) => theme.padding + 'px'};

		@media screen and (orientation: landscape) {
			margin-top: 0px;
		}
	}
`;

export const TitleContainer = styled.div`
	display: inline-block;
	box-sizing: border-box;
	padding-bottom: 0px;
	margin-bottom: ${({ theme }) => theme.padding * 4 + 'px'};
	width: 100%;
	white-space: normal;
`;

export const StyledTagLineSVG = styled(TagLineSVG)`
	display: inline-block;
	width: 100%;
`;

export const MetricsWrapper = styled.div`
	position: relative;
	display: block;
	width: 100%;
	box-sizing: border-box;
`;

export const MetricEntry = styled.div`
	display: inline-block;
	vertical-align: middle;
	box-sizing: border-box;
	padding-bottom: ${({ theme }) => theme.padding * 5 + 'px'};
	width: 16.66%;
	text-align: left;
	color: ${({ theme }) => theme.white};

	:nth-child(n + 4) {
		text-align: right;
		direction: rtl;
	}

	@media (max-width: ${({ theme }) => theme.introBreakpoint}) {
		width: 50%;
		text-align: left;

		:nth-child(odd) {
			text-align: left;
		}
		:nth-child(even) {
			text-align: right;
			direction: rtl;
		}

		padding-bottom: ${({ theme }) => theme.padding * 2 + 'px'};
		@media screen and (orientation: landscape) {
			padding-bottom: 0px;
		}
	}
`;

export const MetricSquare = styled.div`
	display: inline-block;
	vertical-align: middle;
	box-sizing: border-box;
	padding-bottom: ${({ theme }) => theme.padding * 2 + 'px'};
`;

export const MetricValue = styled.div`
	font-size: ${({ theme }) => theme.typography.verylarge};
	padding-top: ${({ theme }) => theme.padding + 'px'};
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
`;

export const MetricLabel = styled.div`
	font-size: ${({ theme }) => theme.typography.small};
	text-transform: uppercase;
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
`;

const moveArrowAni = keyframes`
	0%{
		transform:translateX(0%);
		opacity:1;
	 }
	 35%{
		transform:translateX(0%);
		opacity:1;
	 }
     90% {
		transform:translateX(75%);
		 opacity: 0;
     }
`;

export const StyledMoreSVG = styled(MoreSVG)`
	animation: ${moveArrowAni} 1750ms ease-out infinite;
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
`;

export const InnerLink = styled(Link)`
	pointer-events: all;
	text-decoration: none;
`;

export const SkipButton = styled.div`
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
	font-size: ${({ theme }) => theme.typography.medium};
	text-transform: uppercase;
	letter-spacing: 0.12em;

	margin-top: ${({ theme }) => theme.margin + 'px'};
	padding: ${({ theme }) => theme.padding + 'px'};
	padding-left: ${({ theme }) => theme.padding * 1.25 + 'px'};
	padding-right: ${({ theme }) => theme.padding * 1.25 + 'px'};
	background: ${({ theme }) => theme.black};
	color: ${({ theme }) => theme.white};

	&:hover {
		cursor: pointer;
	}

	span {
		vertical-align: bottom;
		margin-right: 10px;
		line-height: 1;
	}
`;
