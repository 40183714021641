import { css } from 'styled-components/macro';
import variables from './variables';

export default css`
  body {
    background: white;
    color: black;
    padding: 0px;
    margin: 0px;
    font-family: ${variables.fontfamilies.neutralReg};
    height: 100%;
    // Some default letter spacing
    letter-spacing: 1px;
  }
  #root {
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    body {
      overscroll-behavior-y: none;
    }
  }
`;
