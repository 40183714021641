import * as THREE from 'three';
import * as TWEEN from 'tween';

import formatNumber from '../../../helpers/formatNumber';
import { MeshText2D, textAlign } from 'three-text2d';

class SubTile {
  constructor(parent, container, data) {
    this.parent = parent;
    this.parentTile = container;

    // Keep track
    this.positions = { x: 0, y: 0, z: 0 };
    this.scales = { xScale: 0, yScale: 0, zScale: 0 };

    // Create a new group to contain the chart and the top box
    this.group = new THREE.Group();
    this.data = data;

    this.meshLabel = null; // keep track of the MeshText2D object
    this.labelGroup = new THREE.Group(); // So we can the group of 'label' elements: text mesh label and sphere
    this.parentTile.add(this.labelGroup);

    // Animations
    this.positionTween = new TWEEN.Tween(this.group.position);
    this.scaleTween = new TWEEN.Tween(this.group.scale);
    this.labelTween = new TWEEN.Tween(this.labelGroup.position);
    this.labelOpacityTween = { backdrop: null, text: null, shere: null }; // Contains two references
    this.opacityFillTween = null;
    this.colorTween = null;
    this.strokeTween = null;
  }

  initialize(x, y, z, xScale, yScale, zScale, index) {
    // Update the positions and scales
    this.positions.x = x;
    this.positions.y = y;
    this.positions.z = z;
    this.scales.xScale = xScale;
    this.scales.yScale = yScale;
    this.scales.zScale = zScale;

    // Box
    const geometry = new THREE.BoxGeometry(1, 1, 1);

    // UV mapping of the textures
    const texture = new THREE.TextureLoader().load(`${this.parent.data.textures[index].texture}`);

    // Improve quality of the textures; for more info: https://threejs.org/docs/#api/en/textures/Texture.anisotropy
    texture.anisotropy = 16;

    // Get the current height to determine the offset
    const boxHeight = this.positions.y + this.scales.yScale / 2;

    // 100% divided by 6 sides
    const xPercentage = 1 / 6;

    // Determine the percentage to set the UV offset
    let yPercentage;

    // Range startX - endX (so each side get equally used)
    let startX = 0;
    let endX = xPercentage;

    const uvMappingOrder = [
      { triangleAindex: 0, triangleBindex: 1 },
      { triangleAindex: 10, triangleBindex: 11 },
      { triangleAindex: 4, triangleBindex: 5 },
      { triangleAindex: 6, triangleBindex: 7 },
      { triangleAindex: 8, triangleBindex: 9 },
      { triangleAindex: 2, triangleBindex: 3 },
    ];

    for (let index = 0; index < uvMappingOrder.length; index++) {
      // When top or bottom
      if (index === 2 || index === 3) {
        // Same ratio when top
        yPercentage = xPercentage;
      } else {
        // Determine the percentage to set the UV offset
        yPercentage = boxHeight / (this.parent.parent.settings.subTile.mapping.max + 1);
      }

      const triangleAindex = uvMappingOrder[index].triangleAindex;
      const triangleBindex = uvMappingOrder[index].triangleBindex;

      const uvMap = [
        // Left top (0,1)
        new THREE.Vector2(startX, yPercentage),
        // Left Lower bottom (0,0)
        new THREE.Vector2(startX, 0.0),
        // Right Lower bottom (1,0)
        new THREE.Vector2(endX, 0.0),
        // Right top (1,1)
        new THREE.Vector2(endX, yPercentage),
      ];

      geometry.faceVertexUvs[0][triangleAindex] = [uvMap[0], uvMap[1], uvMap[3]];
      geometry.faceVertexUvs[0][triangleBindex] = [uvMap[1], uvMap[2], uvMap[3]];

      // Update offset
      startX += xPercentage;
      endX += xPercentage;
    }

    const material = new THREE.MeshBasicMaterial({
      wireframe: false,
      map: texture,
      opacity: 0,
      transparent: true,
    });

    const cube = new THREE.Mesh(geometry, material);
    // cube.rotateY(Math.PI / 3);

    // Set mouse function on bar
    cube.mouseInteractionAllowed = true;
    cube.referenceObject = this;

    // Add the cube to the group
    this.group.add(cube);

    // Update the position
    this.group.position.x = this.positions.x;
    this.group.position.y = 0;
    this.group.position.z = this.positions.z;
    this.group.scale.x = this.scales.xScale;
    this.group.scale.y = 1;
    this.group.scale.z = this.scales.zScale;

    // Bind the colorTween to the material
    this.colorTween = new TWEEN.Tween(cube.material.color);
    this.opacityFillTween = new TWEEN.Tween(cube.material);

    // Add to the parent scene
    this.parentTile.add(this.group);

    // Add label
    this.createLabel(this.positions.x, 0, this.positions.z);
  }

  createLabel(x, y, z) {
    // Scale that changes the 'scale' of the whole group
    const scale = 0.007; // Rescale the mesh; not sure what is the best way to do so

    // Create label
    this.meshLabel = new MeshText2D(
      `${formatNumber(this.data.value)} ${this.data.prefix.toUpperCase()}`,
      {
        align: textAlign.center,
        font: '110px Neutral Std Medium',
        fillStyle: '#000000',
        antialias: true,
      }
    );

    // Determine the dimensions of the text
    const padding = 40 * scale;
    const textWidth = this.meshLabel.canvas.textWidth * scale;
    const textHeight = this.meshLabel.canvas.textHeight * scale;

    // Set the angle
    this.meshLabel.rotateY(Math.PI / 2);

    // Set the scale
    this.meshLabel.scale.set(scale, scale, scale);

    // Set the position; slightly higher so the label appearss above the sphere (which is added later)]
    this.meshLabel.position.set(0, textHeight * 0.44, 0);

    // Allow opactiy
    this.meshLabel.material.transparent = true;
    this.meshLabel.material.opacity = 0;

    // Make sure it has the same settings as it's backdrop
    this.meshLabel.material.depthTest = false;
    this.meshLabel.material.side = THREE.DoubleSide;

    // Add to the group
    this.labelGroup.add(this.meshLabel);

    // Add a sphere
    const geometry = new THREE.SphereGeometry(0.1, 9, 9);
    const material = new THREE.MeshBasicMaterial({
      color: 'rgb(40,40,40)',
      transparent: true,
      opacity: 0,
    });
    const sphere = new THREE.Mesh(geometry, material);

    // Small offset
    sphere.position.set(0, -1, 0);

    // Add to the group
    this.labelGroup.add(sphere);

    // Create backdrop
    const backdropGeometry = new THREE.PlaneGeometry(
      textWidth + padding * 2,
      textHeight + padding * 2,
      1,
      1
    );
    const backdropmaterial = new THREE.MeshBasicMaterial({
      color: 'rgb(255,255,255)',
      opacity: 0,
      transparent: true,
      side: THREE.DoubleSide,
      depthTest: false,
    });
    const plane = new THREE.Mesh(backdropGeometry, backdropmaterial);
    plane.position.set(-0.25, -0.075, 0.1); // slight offset to make sure the text mesh is on 'top'

    // Rotate
    plane.rotateY(Math.PI / 2);

    // Add to the group
    this.labelGroup.add(plane);

    // Set dedault visibility to false (only enable these on hover or on selection); to make sure there are no artifacts
    this.labelGroup.visible = false;
    this.labelGroup.transparent = true;

    // Set the position
    this.labelGroup.position.x = x;
    this.labelGroup.position.y = y;
    this.labelGroup.position.z = z;

    // Add the reference to the TWEEN
    this.labelOpacityTween.text = new TWEEN.Tween(this.meshLabel.material);
    this.labelOpacityTween.sphere = new TWEEN.Tween(sphere.material);
    this.labelOpacityTween.backdrop = new TWEEN.Tween(plane.material);
  }

  updateTile(x, y, z, xScale, yScale, zScale, duration, delay) {
    this.positionTween
      .stop()
      .delay(delay)
      .to({ x: x, y: y, z: z }, duration)
      .easing(TWEEN.Easing.Cubic.InOut)
      .start();
    this.scaleTween
      .stop()
      .delay(delay)
      .to({ x: xScale, y: yScale, z: zScale }, duration)
      .easing(TWEEN.Easing.Cubic.InOut)
      .start();
  }

  updateColor(r, g, b, duration) {
    const colorRGB = new THREE.Color(r / 255, g / 255, b / 255);
    this.colorTween.stop().to({ r: colorRGB.r, g: colorRGB.g, b: colorRGB.b }, duration).start();
  }

  updateOpacity(target, duration, delay) {
    this.opacityFillTween.stop().delay(delay).to({ opacity: target }, duration).start();
  }

  updateLabel(x, y, z, xScale, yScale, zScale, duration) {
    this.labelTween
      .stop()
      .to({ x: x, y: y + yScale, z: z }, duration)
      .easing(TWEEN.Easing.Cubic.InOut)
      .start();
  }

  hover() {
    if (!this.parent.isSelected && !this.parent.isFocussed) {
      this.updateOpacity(1, 200, 0);
      this.showLabel(500);
    }
  }

  reset() {
    if (!this.parent.isSelected && !this.parent.isFocussed) {
      // Only fade back to 75% when none is selected
      if (this.parent.parent.selectedObject === null) {
        this.updateOpacity(0.8, 350, 0);
      }
      this.hideLabel(500);
    }
  }

  morphIn(duration, delay) {
    this.updateTile(
      this.positions.x,
      this.positions.y,
      this.positions.z,
      this.scales.xScale,
      this.scales.yScale,
      this.scales.zScale,
      duration,
      delay
    );
    this.updateOpacity(0.8, duration * 0.75, delay * 1.25);
  }

  morphOut(duration, delay) {
    this.updateTile(
      this.positions.x,
      0,
      this.positions.z,
      this.scales.xScale,
      0.1,
      this.scales.zScale,
      duration,
      delay
    );
    this.updateOpacity(0, duration * 0.75, delay * 0.75);
  }

  showLabel(duration) {
    this.updateLabel(
      this.positions.x,
      this.positions.y,
      this.positions.z,
      this.scales.xScale,
      this.scales.yScale / 2 + 2.5, // Add some offset from the pilar > label
      this.scales.zScale,
      duration
    );
    this.setLabelOpacity(1, duration);
  }

  hideLabel(duration) {
    // Hide the label
    this.updateLabel(
      this.positions.x,
      this.positions.y,
      this.positions.z,
      this.scales.xScale,
      0,
      this.scales.zScale,
      duration
    );
    this.setLabelOpacity(0, duration);
  }

  setLabelOpacity(target, duration) {
    // Update
    this.meshLabel.updateText();
    // Toggle visiblity based on target alpha
    this.labelOpacityTween.backdrop
      .stop()
      .onComplete(() => {
        if (target === 0) this.labelGroup.visible = false;
      })
      .onStart(() => {
        if (target !== 0) this.labelGroup.visible = true;
      })
      .to({ opacity: target }, duration)
      .start();
    this.labelOpacityTween.text.stop().to({ opacity: target }, duration).start();
    this.labelOpacityTween.sphere.stop().to({ opacity: target }, duration).start();
  }
}

export default SubTile;
