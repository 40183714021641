import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-align: left;
	line-height: 0px;
	top: 0px;
	display: block;
	background: rgb(71, 76, 88);
	z-index: 0;
`;

export const VisualContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: grab;
	&.hovered {
		cursor: pointer;
	}
`;

export const HTML2DContainer = styled.div`
	position: absolute;
	width: 100vw;
	height: 100vw;
	display: block;
	background: none;
	pointer-events: none;

	.label {
		display: inline-block;
		vertical-align: bottom;
		text-transform: uppercase;
		background: ${({ theme }) => theme.black};
		font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
		font-size: ${({ theme }) => theme.typography.labels3d};
		transform: translateY(-125%);
		color: ${({ theme }) => theme.white};
		transition: opacity 500ms;
		opacity: 0;
		line-heigth: 1;

		span {
			padding-top: ${({ theme }) => theme.padding * 0.75 + 1 + 'px'};
			padding-bottom: ${({ theme }) => theme.padding * 0.5 + 'px'};
			padding-right: ${({ theme }) => theme.padding * 0.5 + 1 + 'px'};
			padding-left: ${({ theme }) => 3 + theme.padding * 0.5 + 'px'};
			vertical-align: middle;

			background: ${({ theme }) => theme.black};
			letter-spacing: 0.1em;
		}

		&.show {
			opacity: 1;
		}
	}
`;
