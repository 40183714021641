import React from 'react';

import DescriptionContainer from '../../components/DescriptionContainer/DescriptionContainer';
import MetricsContainer from '../../components/MetricsContainer/MetricsContainer';

import { easeQuadOut, easeSinInOut } from 'd3-ease';
import { animated, useSpring } from 'react-spring';

import {
  BottomStrip,
  CenterContainer,
  CloseBackground,
  ContentWrapper,
  CreditsLink,
  EntryNavigation,
  EntryNavigationText,
  EntryNoVideo,
  EntryThumbnail,
  ExternalLink,
  FullWidth,
  HeaderInfo,
  NavigationWrapper,
  NextEntry,
  PreviousEntry,
  StyledExternalLinkSVG,
  StyledNextSVG,
  StyledPreviousSVG,
  Title,
  TopWrapper,
  Video,
  VideoContainer,
  VideoWrapper,
  Wrapper,
} from './SingleExperiment.styled';

// Animated html
const AniWrapper = animated(Wrapper);
const AniTopWrapper = animated(TopWrapper);

const SingleExperiment = (props) => {
  const { entry, previousEntry, nextEntry, extremeValues } = props;

  // Slide in main content
  const slideIn = useSpring({
    delay: 150,
    config: { duration: 500, easing: easeSinInOut },
    to: { opacity: 1, transform: 'translateY(0vh)' },
    from: { opacity: 0, transform: 'translateY(50vh)' },
  });

  // Fadein header after slide in
  const fadeIn = useSpring({
    delay: 650,
    config: { duration: 750, easing: easeQuadOut },
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  // Check if has URL
  const hasExternalLink = (url) => {
    if (url !== '') return true;
    return false;
  };

  const goToTop = (event) => {
    // Scroll back to top when navigating throught the entries.
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <AniWrapper style={slideIn}>
      <AniTopWrapper color={entry.color.rgb} style={fadeIn}>
        <CloseBackground to={`/overview`} />
        <BottomStrip />
        <VideoContainer>
          <HeaderInfo>
            <Title>{entry.title ? entry.title : entry.short}</Title>
            <br />
            {entry.launchButtons.map(
              ({ url, text }, index) =>
                hasExternalLink(url) && (
                  <ExternalLink key={index} href={url} target="blank">
                    <span>{text}</span>
                    <StyledExternalLinkSVG />
                  </ExternalLink>
                )
            )}
          </HeaderInfo>
          <VideoWrapper>
            {entry.media.mp4 ? (
              <Video
                controls
                loop
                autoPlay
                muted
                poster={entry.media.png}
                src={entry.media.mp4}
                type="video/mp4"
              />
            ) : (
              <EntryNoVideo backgroundImage={entry.media.png} />
            )}
          </VideoWrapper>
        </VideoContainer>
      </AniTopWrapper>
      <ContentWrapper>
        <CenterContainer>
          <DescriptionContainer description={entry.descriptions} />
          {/* Display the metrics */}
          <MetricsContainer
            // Revere metrics to match the visual representation of these metrics in 3D
            metrics={entry.metrics}
            extremeValues={extremeValues}
          />
          <FullWidth className="centerLink">
            {entry.launchButtons.map(
              ({ url, text }, index) =>
                hasExternalLink(url) && (
                  <ExternalLink key={index} href={url} target="blank">
                    <span>{text}</span>
                    <StyledExternalLinkSVG />
                  </ExternalLink>
                )
            )}
          </FullWidth>
        </CenterContainer>
        {/* Credits */}
        <FullWidth className="center">
          <CreditsLink href="https://www.cleverfranke.com/" target="blank">
            experiment by CLEVER°FRANKE
          </CreditsLink>
        </FullWidth>
        {/* Prev and next */}
        {/* Please note the previous fetches the next entry this due visual logic */}
        <NavigationWrapper>
          <PreviousEntry>
            {nextEntry !== null && (
              <EntryNavigation to={`/experiment/${nextEntry.slug}`} onClick={goToTop}>
                <EntryNavigationText>
                  <span>
                    <StyledPreviousSVG />
                    Previous experiment
                  </span>
                </EntryNavigationText>
                <EntryThumbnail colorRGB={nextEntry.colorRGB} src={nextEntry.media.png} />
              </EntryNavigation>
            )}
          </PreviousEntry>
          {/* Please note the next fetches the previous entry this due visual logic */}
          <NextEntry>
            {previousEntry !== null && (
              <EntryNavigation to={`/experiment/${previousEntry.slug}`} onClick={goToTop}>
                <EntryNavigationText>
                  <span>
                    Next experiment
                    <StyledNextSVG />
                  </span>
                </EntryNavigationText>
                <EntryThumbnail colorRGB={previousEntry.colorRGB} src={previousEntry.media.png} />
              </EntryNavigation>
            )}
          </NextEntry>
        </NavigationWrapper>
        {/* End wrapper */}
      </ContentWrapper>
    </AniWrapper>
  );
};

export default SingleExperiment;
