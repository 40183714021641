import React from 'react';

import Header from './../../components/Header/Header';
import ExperimentsList from './../../components/ExperimentsList/ExperimentsList';

const List = props => {
	const { siteData, sortedEntries } = props;
	return (
		<>
			{/* Header; depending on view determine to show menu or closing icon */}
			<Header title={siteData.title} showMenu={false} showClose={true} />
			<ExperimentsList sortedEntries={sortedEntries} />
		</>
	);
};

export default List;
