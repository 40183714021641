import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as CloseSVG } from '../../images/icons/close.svg';

export const Container = styled.div`
	position: absolute;
	margin: 0px;
	padding: 0px;
	width: 100%;
	text-align: center;
	padding-top: ${({ theme }) => theme.padding * 10 + 'px'};

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		padding-top: ${({ theme }) => theme.padding * 12 + 'px'};
	}
`;

export const CloseBackground = styled(Link)`
	position: absolute;
	display: inline-block;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;

	&:hover {
		cursor: default;
	}
`;

export const Close = styled(Link)`
	position: relative;
	display: block;
	text-align: right;
`;

export const StyledCloseSVG = styled(CloseSVG)`
	width: 48px;
	width: 48px;
	color: ${({ theme }) => theme.white};
	transition: all 500ms;
	pointer-events: all;

	&:hover {
		cursor: pointer;
		color: ${({ theme }) => theme.black};
	}
`;
