export const onMouseDown = (event, parent) => {
	// Update the vector
	parent.mouseDownVector.x = (event.clientX / parent.width) * 2 - 1;
	parent.mouseDownVector.y = -(event.clientY / parent.height) * 2 + 1;

	// Only allow left mouse button
	if (event.button === 0) {
		// Re-enable controls
		parent.controls.enabled = true;
	}
};
