import React, { Component } from 'react';
import IsoMetricChart from '../IsoMetricChart/IsoMetricChart';
import formatNumber from '../../helpers/formatNumber';

import {
	Container,
	MetricsWrapper,
	MetricEntry,
	MetricSquare,
	MetricValue,
	MetricLabel
} from './MetricsContainer.styled';

class MetricsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			highlightedMetric: null
		};
		// Set delay
		this.delayChart = true;
	}

	hoverMetric = metricEntry => {
		if (metricEntry === null) this.setState({ highlightedMetric: null });
		else this.setState({ highlightedMetric: metricEntry.key });
	};

	componentDidUpdate() {
		this.delayChart = false;
	}

	render() {
		const { metrics, extremeValues } = this.props;

		// Because we do not want to reverse the original metric array we have to create a new one and reverse the metrics.
		const reversedMetrics = [...metrics].reverse();
		return (
			<Container>
				<IsoMetricChart
					width={300}
					height={350}
					// Delay animations when new page
					delay={this.delayChart ? 750 : 0}
					data={reversedMetrics}
					extremeValues={extremeValues}
					highlightedMetric={this.state.highlightedMetric}
					hoverMetric={this.hoverMetric}
				/>
				<MetricsWrapper>
					{reversedMetrics.map((metricEntry, index) => (
						<MetricEntry key={index} className={index % 2 ? 'right' : 'left'}>
							<MetricSquare
								onMouseEnter={() => {
									this.hoverMetric(metricEntry);
								}}
								onMouseLeave={() => {
									this.hoverMetric(null);
								}}
								className={
									this.state.highlightedMetric === null ||
									this.state.highlightedMetric === metricEntry.key
										? 'default'
										: 'fade'
								}>
								<MetricValue>{formatNumber(metricEntry.value)}</MetricValue>
								<MetricLabel>
									{metricEntry.label}
									{/* Only show metrics when not... */}
									{metricEntry.key !== 'code_commits' &&
									metricEntry.key !== 'total_views'
										? ` in ${metricEntry.prefix}`
										: ''}
								</MetricLabel>
							</MetricSquare>
						</MetricEntry>
					))}
				</MetricsWrapper>
			</Container>
		);
	}
}

export default MetricsContainer;
