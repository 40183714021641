import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	z-index: 11;
	pointer-events: none;
	background: ${({ theme }) => theme.white};
`;

const pulseAni = keyframes`
	0%{

		opacity:0;
	 }
	 50%{

		opacity:1;
	 }
     100% {

		 opacity: 0;
     }
`;

export const CenterContainer = styled.div`
	position: absolute;
	display: block;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	animation: ${pulseAni} 1200ms ease-out infinite;
`;
