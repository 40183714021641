import styled from 'styled-components';

export const Wrapper = styled.div`
	display: inline-block;
	width: 55%;

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		width: 100%;
	}
`;

export const Title = styled.div`
	font-size: ${({ theme }) => theme.typography.small};
	font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
	text-transform: uppercase;
	letter-spacing: 0.12em;
	margin-bottom: ${({ theme }) => theme.margin / 2 + 'px'};
	padding: ${({ theme }) => theme.padding / 2 + 'px'};
`;

export const Description = styled.div`
	display: inline-block;
	margin-bottom: ${({ theme }) => theme.margin * 2 + 'px'};
	padding: ${({ theme }) => theme.padding / 2 + 'px'};
	font-size: ${({ theme }) => theme.typography.medium};
	line-height: 150%;
`;

export const Container = styled.div`
	padding-right: 15%;

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		padding-right: 0;
	}
`;
