export const onMouseMove = (event, parent) => {
	// calculate mouse position in normalized device coordinates
	// (-1 to +1) for both components
	parent.mouse3D.x = (event.clientX / parent.width) * 2 - 1;
	parent.mouse3D.y = -(event.clientY / parent.height) * 2 + 1;

	// Check if intersects
	parent.raycaster.setFromCamera(parent.mouse3D, parent.camera);

	// Convert object array to array
	const interactionObjects = Object.keys(parent.interactionObjects).map(
		i => parent.interactionObjects[i]
	);

	// calculate objects intersecting the picking ray
	let intersects = parent.raycaster.intersectObjects(interactionObjects, true);

	if (intersects.length > 0) {
		for (var i = 0; i < intersects.length; i++) {
			const object = intersects[i].object;

			// Get the selected object
			if (object.mouseInteractionAllowed && parent.selectedObject === null) {
				// Set if none is hovered
				if (parent.hoverObject === null) {
					parent.hoverObject = object;
					// Show labels
					if (parent.lastSortingMethod === 'default') {
						object.referenceObject.parent.hover();
					} else {
						// Show specific
						object.referenceObject.parent.hoverSpecificSubTile(
							parent.lastSortingMethod
						);
					}
				} else if (
					parent.hoverObject.referenceObject.parent.data.slug !==
					object.referenceObject.parent.data.slug
				) {
					// Reset previous
					if (parent.lastSortingMethod === 'default') {
						parent.hoverObject.referenceObject.parent.reset();
					} else {
						parent.hoverObject.referenceObject.parent.resetSpecificSubTile(
							parent.lastSortingMethod
						);
					}

					// Set new
					parent.hoverObject = object;

					// Show labels
					if (parent.lastSortingMethod === 'default') {
						object.referenceObject.parent.hover();
					} else {
						// Show specific
						object.referenceObject.parent.hoverSpecificSubTile(
							parent.lastSortingMethod
						);
					}
				}

				// De-focus other tiles
				if (parent.hoverObject !== null) {
					parent.setProjectFocus(parent.hoverObject.referenceObject.parent);
				}
			} else if (
				object.mouseInteractionAllowed &&
				parent.selectedObject !== null
			) {
				// Make sure only the pointer-cursor will show up when id(slug) is the same (selected & hover)
				if (
					parent.selectedObject !== null &&
					object.referenceObject.parent.data.slug ===
						parent.selectedObject.data.slug
				) {
					parent.hoverObject = object;
				}
			}
		}
	} else {
		// Reset
		if (parent.hoverObject !== null) {
			// Reset hover object
			if (parent.lastSortingMethod === 'default') {
				parent.hoverObject.referenceObject.parent.reset();
			} else {
				parent.hoverObject.referenceObject.parent.resetSpecificSubTile(
					parent.lastSortingMethod
				);
			}
			// Reset all projects to default
			parent.resetProjectFocus();
		}
		// Reset object
		parent.hoverObject = null;
	}

	// Update the cursor depending if hovering on an object
	parent.updateCursor();
};
