import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as LinkSVG } from '../../images/icons/link.svg';
import { ReactComponent as NextSVG } from '../../images/icons/next.svg';
import { ReactComponent as PreviousSVG } from '../../images/icons/previous.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 9;
  pointer-events: auto;
  opacity: 0;
`;

export const TopWrapper = styled.div`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  background: rgba(
    ${(props) => props.color.r},
    ${(props) => props.color.g},
    ${(props) => props.color.b},
    0.5
  );
`;

export const CloseBackground = styled(Link)`
  position: absolute;
  display: inline-block;
  background: none;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: default;
  }
`;

export const BottomStrip = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 20%;
  bottom: 0px;
  z-index: 0;
  background: white;
`;

export const VideoContainer = styled.div`
  margin-top: 140px;
  width: 100%;
  margin-bottom: -6px;
  text-align: center;
`;

export const HeaderInfo = styled.div`
  position: absolute;
  display: inline-block;
  top: 50%;
  z-index: 10;
  max-width: 55%;
  padding: ${({ theme }) => theme.padding + 'px'};
  color: ${({ theme }) => theme.white};
  text-align: left;
  transform: translateX(-5%);

  @media (max-width: ${({ theme }) => theme.breakpoint}) {
    position: relative;
    display: block;
    top: 0%;
    max-width: 100%;
    transform: translateX(0%);
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: inline-block;
  z-index: 9;
  width: 90%;
  max-width: 1440px;
  box-sizing: border-box;
  padding-left: 4em;
  padding-right: 4em;
  padding-bottom: 0px;
  pointer-events: none;

  @media (max-width: ${({ theme }) => theme.breakpoint}) {
    width: 100%;
    padding: 0px;
    display: block;
  }
`;

export const Video = styled.video`
  z-index: 9;
  width: 100%;
  pointer-events: all;
  display: block;

  &:focus {
    outline: none;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  background: ${({ theme }) => theme.white};
  z-index: 9;
  opacity: 1;
  padding-top: ${({ theme }) => theme.padding * 2 + 'px'};
`;

export const CenterContainer = styled.div`
  margin-top: 52px;
  display: inline-block;
  width: 90%;
  max-width: 1440px;
  padding-left: 4em;
  padding-right: 4em;
  box-sizing: border-box;
  text-align: left;
  background: ${({ theme }) => theme.white};
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoint}) {
    width: 100%;
    padding-left: ${({ theme }) => theme.padding + 'px'};
    padding-right: ${({ theme }) => theme.padding + 'px'};
  }
`;

export const StyledNextSVG = styled(NextSVG)`
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: bottom;
`;

export const StyledPreviousSVG = styled(PreviousSVG)`
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: bottom;
`;

export const Title = styled.div`
  display: inline-block;
  font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
  font-size: ${({ theme }) => theme.typography.extralarge};
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
  padding: ${({ theme }) => theme.padding * 1.5 + 'px'};
  padding-left: ${({ theme }) => theme.padding * 1.75 + 'px'};
  padding-rigth: ${({ theme }) => theme.padding * 1.75 + 'px'};

  @media (max-width: ${({ theme }) => theme.breakpoint}) {
    font-size: ${({ theme }) => theme.typography.extralarge};
  }
`;

export const ExternalLink = styled.a`
  display: inline-block;
  padding: ${({ theme }) => theme.padding * 2 + 'px'};
  margin-top: ${({ theme }) => theme.margin + 'px'};
  font-size: ${({ theme }) => theme.typography.large};
  background: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
  text-decoration: none;
  transition: all 500ms;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.black};
  }

  span {
    vertical-align: bottom;
    margin-right: 10px;
    line-height: 1;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint}) {
    padding: ${({ theme }) => theme.padding + 'px'};
  }
`;

export const ExternalLinkSmall = styled.a`
  display: inline-block;
  padding: ${({ theme }) => theme.padding * 2 + 'px'};
  font-size: ${({ theme }) => theme.typography.medium};
  background: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  border: 1px solid black;
  text-decoration: none;
  transition: all 500ms;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
  padding-top: ${({ theme }) => 1 + theme.padding * 2 + 'px'};
  margin-top: ${({ theme }) => theme.margin * 3 + 'px'};

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.black};
  }

  span {
    vertical-align: bottom;
    margin-right: 10px;
    line-height: 1;
  }
`;

export const CreditsLink = styled.a`
  display: inline-block;
  padding: ${({ theme }) => theme.padding * 1 + 'px'};
  padding-left: ${({ theme }) => 0 + 'px'};
  padding-right: ${({ theme }) => 0 + 'px'};
  font-size: ${({ theme }) => theme.typography.small};
  margin-top: ${({ theme }) => theme.margin * 8 + 'px'};
  margin-bottom: ${({ theme }) => theme.margin * 16 + 'px'};
  font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: ${({ theme }) => theme.black};
  text-decoration: none;
  transition: all 500ms;

  border-top: 1px solid ${({ theme }) => theme.black};

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

export const FullWidth = styled.div`
  padding-top: ${({ theme }) => theme.padding + 'px'};
  padding-bottom: ${({ theme }) => theme.padding + 'px'};

  &.center {
    background: ${({ theme }) => theme.white};
    text-align: center;
  }

  &.centerLink {
    @media (max-width: ${({ theme }) => theme.breakpoint}) {
      text-align: center;
    }
  }
`;

export const Info = styled.div`
  font-size: ${({ theme }) => theme.typography.medium};
  padding: ${({ theme }) => theme.padding / 2 + 'px'};
  margin: ${({ theme }) => theme.margin + 'px'};
  width: 100%;
`;

export const StyledExternalLinkSVG = styled(LinkSVG)`
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
`;

export const StyledExternalLinkSmallSVG = styled(StyledExternalLinkSVG)`
  width: 16px;
  height: 16px;
`;

export const NavigationWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.white};
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakpoint}) {
    display: block;
  }
`;

export const PreviousEntry = styled.div`
	position: relative;
	display: inline-block;
	width: 50%;
	text-align: left;
	box-sizing: border-box;
	padding: 0px;
	margin: 0px;
	background: ${({ theme }) => theme.black};
	max-height: 160px
	overflow: hidden;

	@media (max-width: ${({ theme }) => theme.breakpoint}) {
		width: 100%;
		display:block;
	}
`;

export const NextEntry = styled.div`
  position: relative;
  display: inline-block;
  width: 50%;
  text-align: right;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  background: ${({ theme }) => theme.black};
  max-height: 160px;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoint}) {
    width: 100%;
    display: block;
  }
`;

export const EntryNavigation = styled(Link)`
  font-size: ${({ theme }) => theme.typography.medium};
  margin-bottom: ${({ theme }) => theme.margin / 2 + 'px'};
  color: ${({ theme }) => theme.black};
  text-decoration: none;
  &:hover {
    img {
      opacity: 1;
    }
  }
`;

export const EntryThumbnail = styled.img`
  display: block;
  width: 100%;
  opacity: 0.35;
  transition: opacity 500ms;
  transform: translateY(-33.33%);

  @media (max-width: ${({ theme }) => theme.breakpoint}) {
    transform: translateY(0px);
  }
`;

export const EntryNavigationText = styled.div`
  position: absolute;
  text-align: center;
  color: ${({ theme }) => theme.white};
  z-index: 9;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: ${({ theme }) => theme.typography.small};
  font-family: ${({ theme }) => theme.fontfamilies.neutralMed};
  padding: ${({ theme }) => theme.padding + 'px'};
  text-transform: uppercase;
  width: 100%;
  span {
    vertical-align: bottom;
    letter-spacing: 0.12em;

    svg {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
`;
export const EntryNoVideo = styled.div`
  z-index: 9;
  width: 100%;
  pointer-events: all;
  display: block;
  aspect-ratio: 16/9;
  position: relative;
  background: no-repeat center center / cover url(${(props) => props.backgroundImage});

  &:focus {
    outline: none;
  }
`;
