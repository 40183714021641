import React from 'react';
import { useSpring, animated } from 'react-spring';

import {
	CloseBackground,
	Wrapper,
	ContentWrapper,
	CenterContainer,
	Title,
	TagLine,
	StyledLogoSVG,
	Container,
	Description,
	ExternalLinkSmall,
	StyledExternalLinkSmallSVG
} from './ExperimentsAbout.style.js';

const AniWrapper = animated(Wrapper);

const ExperimentsAbout = props => {
	const { aboutData } = props;
	const fadeIn = useSpring({
		config: { duration: 350 },
		to: { opacity: 1 },
		from: { opacity: 0 }
	});

	return (
		<AniWrapper style={fadeIn}>
			<CloseBackground to={`/overview`} />
			<ContentWrapper>
				<CenterContainer>
					<Container>
						<TagLine>
							<StyledLogoSVG />
						</TagLine>
						<Title>About</Title>
						<Description>{aboutData}</Description>
						<ExternalLinkSmall
							href="https://medium.com/clever-franke/c-f-experiments-learning-and-growing-through-experimentation-ae291d9bbb48"
							target="blank">
							<span>Read more about this project</span>
							<StyledExternalLinkSmallSVG />
						</ExternalLinkSmall>
						<br />
						<br />
						<ExternalLinkSmall
							href="https://www.cleverfranke.com/"
							target="blank">
							<span>Visit CLEVER°FRANKE</span>
							<StyledExternalLinkSmallSVG />
						</ExternalLinkSmall>
					</Container>
				</CenterContainer>
			</ContentWrapper>
		</AniWrapper>
	);
};

export default ExperimentsAbout;
